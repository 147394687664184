@import '../../../../css/variables';
@import '../../../../css/breakpoints';

:root {
  --active: white;
}

.topbarNav {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  @include media-breakpoint-down(md) {
    display: none;
  }

  .topbarItem {
    padding-left: 0.5em;
    padding-right: 1.15em;

    &.active {
      .topbarLink {
        border-bottom: 4px solid #ff7900;
        color: var(--active);
        padding-bottom: calc(0.6rem - 4px);
      }
    }

    .topbarLink {
      color: #c3c3c3;
      text-transform: uppercase;
      font-size: 13px;
      font-family: Montserrat, sans-serif;
      white-space: nowrap;
      padding: 1rem 0;
      display: block;

      &:hover {
        color: var(--active);
      }
    }
  }
}
