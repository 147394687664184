@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.container {
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: flex-end;
  justify-content: center;

  z-index: 1000;
}

.modal {
  width: 550px;
  max-width: 80vw;
  position: relative;
  background: white;
  color: black;
  border-radius: 6px;
  box-shadow: 0px 17px 56px black;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  padding: 18px 10px 18px 20px;
  margin: 0;
  animation: fadein 0.25s ease-in-out;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    gap: 20px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal .close {
  transform: rotate(180deg);
  width: 20px;
  height: 20px;
  border: none;
  padding: 0;
  margin: 0;
  background: url('../../../../img/icon-close-gray.svg');
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
}

.modal .title {
  align-self: flex-end;
  line-height: 1.2;
  text-align: left;
  width: 100%;
  color: black;
  font-family: 'Lato', Helvetica, Arial, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
}

.modal .buttons {
  justify-content: center;
}
