@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.header {
  text-align: center;
  width: 100%;

  h1 {
    padding-top: 15px;
    padding-bottom: 19px;
  }
}

.breadcrumb {
  padding: 2%;
  margin-bottom: 30px;
  color: #747474;
  cursor: pointer;
  padding-top: 25px;
  padding-bottom: 25px;

  h2 {
    display: inline-block;
    font-size: 24px;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    line-height: 32px;
    text-align: left;
    color: white;
    text-transform: initial;
    font-weight: 700;
    float: left;
    margin-left: 20px;
    width: auto;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      line-height: 26px;
      margin-left: 16px;
    }
  }

  &:hover {
    color: white;
  }

  img {
    margin-left: 18px;
    float: left;
    margin-right: 20px;
    padding-top: 2px;
  }
}

.containerSearchRecipes {
  height: 100%;
  background-color: #151515;
  overflow-y: auto;
  height: 100%;
}

.nutritionSearchModal {
  .searchContainer {
    float: left;
    height: 35vh;
    width: 31%;

    background-size: 200%;
    transition: background-size 0.3s;

    @include media-breakpoint-down(md) {
      background-size: cover;
    }

    &:hover {
      background-size: 210%;
      transition: background-size 0.3s;

      @include media-breakpoint-down(md) {
        background-size: cover;
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      height: 250px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 160px;
    }
  }

  .container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0% 3% 3% 3%;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  .preContainer {
    background-color: #151515;
    display: block;
  }
}

.searchContainer {
  display: flex;
  height: 205px;
  width: 32%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  position: relative;

  background-size: 100%;
  transition: background-size 0.3s;

  &:hover {
    background-size: 105%;

    @include media-breakpoint-down(lg) {
      background-size: 100%;
    }
  }

  &.card {
    width: 100%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
  }

  &.all { background-image: url(/src/img/nutrition/all.jpg); }
  &.breakfast { background-image: url(/src/img/nutrition/desayunos.jpg); }
  &.lunch { background-image: url(/src/img/nutrition/comidas.jpg); }
  &.dinner { background-image: url(/src/img/nutrition/cenas.jpg); }
  &.snack { background-image: url(/src/img/nutrition/snacks.jpg); }
  &.dessert { background-image: url(/src/img/nutrition/postres.jpg); }

  @include media-breakpoint-down(md) {
    width: 100%;
    margin: 0;
    margin-bottom: 1em;
  }

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(18, 18, 18, 30%);
  }

  .title {
    line-height: 19px;
    color: white;
    font-family: 'Lato', Helvetica, Arial, serif;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    z-index: 1;
    position: relative;
    letter-spacing: 1px;
    text-shadow: 3px 0px 10px #0000005a;
  }
}