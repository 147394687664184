@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.myCollectionCard {
  position: relative;

  display: block;

  width: 257px;
  height: 130px;

  .overlay {
    position: absolute; 
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  .red{
    background: $red;
  }
  .orange{
    background: $orange;
  }
  .yellow{
    background: $yellow;
  }
  .green{
    background: $green;
  }
  .blue{
    background: $blue;
  }
  .blackblue{
    background: $blackblue;
  }
  .violet{
    background: $violet;
  }
  .purple{
    background: $purple;
  }
  .pink{
    background: $pink;
  }
  .salmon{
    background: $salmon;
  }

  .shadow {
    background: linear-gradient(transparent, rgba(0, 0, 0, .6));
    pointer-events: none;
  }

  .content {
    display: flex;
    align-items: flex-end;

    width: 100%;
    height: 100%;

    padding: 10px 15px;

    .collectionName {
      color: white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      font-family: 'Montserrat', Helvetica, Arial, serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;

      z-index: 1;
    }
  }

  @include media-breakpoint-up(xxl) {
    width: 328px;
    height: 155px;
  }
}