@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.header {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

%header_playlist {
  color: white;
  margin: 0 45px;
  text-transform: uppercase;
  padding: 60px 0;
  min-height: 210px;

  span {
    font-family: 'Lato', Helvetica, Arial, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 32px;

    &.breadcrumbs {
      min-height: 1em;
      a {
        display: inline-block;

        &:hover:after {
          content: '';
          background-image: url('@/img/chevron-left-white.png');
          transition: all ease-in 0.2s;
        }

        &:after {
          margin: 0 0.5em;
          background-image: url('@/img/chevron-left.png');
          background-size: 5px 10px;
          display: inline-block;
          width: 5px;
          height: 10px;
          content: '';
        }
      }
    }
  }

  h3 {
    font-family: 'Montserrat', Helvetica, Arial, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 10px;
    display: inline-block;
    line-height: 34px;

    @include media-breakpoint-down(lg) {
      font-size: 22px;
      line-height: 28px;
    }
  }
}

.header_playlist {
  @extend %header_playlist;

  @include media-breakpoint-down(sm) {
    margin: 0 25px;
    padding: 40px 0;
  }

  .followers {
    color: #fff;
    opacity: 0.6;
    font-size: 11px;
    line-height: normal;
    text-shadow: 1px 2px 25px #000000c2;
    display: inline-block;
  }

  p {
    text-transform: initial;
    margin: 10px 0 20px 0;
    font-size: 15px;
    line-height: 20px;

    @include media-breakpoint-down(sm) {
      margin: 10px 0 15px 0;
    }
  }

  button {
    background-color: transparent;
    border-radius: 20px;
    font-family: 'Lato', Helvetica, Arial, serif;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    border: none;
    padding: 8px 10px;
    width: 105px;
    border: 1px #ff7900 solid;
    letter-spacing: 1px;
    &[disabled] {
      opacity: 0.3;
    }
  }

  .fav {
    background-color: #ff7900;
  }
}

.header_playlist_category {
  @extend %header_playlist;

  @include media-breakpoint-down(sm) {
    padding: 30px 0px;
    min-height: 150px;
  }

  img {
    width: 260px;
    display: block;
  }
}

.header_playlist_title {
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  text-transform: uppercase;
}
