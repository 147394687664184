@import '../../../../../css/variables.scss';
@import '../../../../../css/breakpoints.scss';

.button {
  background: transparent 0% 0% no-repeat padding-box;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 600 13px/18px Montserrat;
  letter-spacing: 0.5px;
  padding: 8px 10px;
  text-align: center;
  min-width: 95px;
  text-transform: uppercase;
  color: #b9b9b9;

  &[disabled] {
    opacity: 0.3;
  }

  &.big {
    font-size: 16px;
    line-height: 16px;
    min-width: 340px;
    margin-top: 20px;
    height: 50px;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 8px;

    @include media-breakpoint-down(md) {
      min-width: 100%;
    }
  }

  &.default {
    font-size: 13px;
    line-height: 22px;
    min-height: 40px;
    padding: 8px 16px;
  }

  &.small {
    font-size: 12px;
    line-height: 18px;
    border-radius: 4px;
  }

  &.bold {
    font-weight: 600;
    letter-spacing: 0.56px;
    text-transform: uppercase;
  }

  &.primary {
    $bgcolor: #ff7900;
    background-color: $bgcolor;
    color: #fff;

    &:hover {
      background-color: lighten($bgcolor, +10%);
    }

    &:disabled,
    &.disabled {
      background-color: #8b4d20;
      color: rgb(153, 151, 151);
      transition: all 0.25s ease-in-out;
      cursor: auto;
    }
  }

  &.secondary {
    $bgcolor: #2f2f2f;
    background-color: $bgcolor;
    color: #b9b9b9;

    &:hover {
      background-color: lighten($bgcolor, +10%);
      cursor: pointer;
    }

    icon {
      color: #ff7900;
    }
  }

  &.danger {
    $bgcolor: #f13b46;
    background-color: $bgcolor;
    color: white;

    &:hover {
      background-color: lighten($bgcolor, +5%);
      cursor: pointer;
    }
  }

  &.textbutton {
    color: #b9b9b9;
    padding: 5px 15px;
    $bgcolor: transparent;
    background-color: $bgcolor;
    border-radius: 20px;
    margin-top: 5px;
    line-height: 23px;
    min-height: auto;

    &:hover {
      background-color: rgba(255, 255, 255, 0.075);
      cursor: pointer;
    }
  }

  &.terciary {
    $bgcolor: #707070;
    background-color: $bgcolor;
    color: white;

    &:hover {
      background-color: lighten($bgcolor, +10%);
      cursor: pointer;
    }

    icon {
      color: #ff7900;
    }

    &:disabled,
    &.disabled {
      background-color: #2f2f2f;
      color: #666;
      transition: all 0.25s ease-in-out;
      cursor: auto;
    }

    &.ml10 {
      margin-left: 10px;
    }

    &.ml15 {
      margin-left: 15px;
    }

    &.w100 {
      width: 100%;
      height: 50px;
    }

    &.mt10 {
      margin-top: 10px;
    }

    &.mt30 {
      margin-top: 30px;
    }
  }

  &.transparent {
    background-color: transparent;
    color: #b9b9b9;
    &:hover {
      background-color: rgba(255, 255, 255, 0.075);
    }
  }

  &.buttonGrey {
    background-color: #707070;
    color: white;
    margin-left: 15px;

    &:hover {
      background-color: lighten(#707070, +10%);
    }
  }

  .leftIcon {
    float: left;
  }

  .content {
    display: flex;

    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.buttongroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  :last-child {
    margin-right: 0;
  }

  button {
    flex-basis: 0;
    flex-grow: 1;
  }
}

.miniIcon {
  width: 30px;
  float: left;
}
