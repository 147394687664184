/* lato-100 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url('../font/Lato/lato-v17-latin-100.woff2') format('woff2');
}
/* lato-100italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: url('../font/Lato/lato-v17-latin-100italic.woff2') format('woff2');
}
/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../font/Lato/lato-v17-latin-300.woff2') format('woff2');
}
/* lato-300italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url('../font/Lato/lato-v17-latin-300italic.woff2') format('woff2');
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../font/Lato/lato-v17-latin-regular.woff2') format('woff2');
}
/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../font/Lato/lato-v17-latin-italic.woff2') format('woff2');
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../font/Lato/lato-v17-latin-700.woff2') format('woff2');
}
/* lato-700italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('../font/Lato/lato-v17-latin-700italic.woff2') format('woff2');
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../font/Lato/lato-v17-latin-900.woff2') format('woff2');
}
/* lato-900italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: url('../font/Lato/lato-v17-latin-900italic.woff2') format('woff2');
}

/* montserrat-100 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('../font/Montserrat/montserrat-v15-latin-100.woff2') format('woff2');
}
/* montserrat-100italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: url('../font/Montserrat/montserrat-v15-latin-100italic.woff2') format('woff2');
}
/* montserrat-200 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('../font/Montserrat/montserrat-v15-latin-200.woff2') format('woff2');
}
/* montserrat-200italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: url('../font/Montserrat/montserrat-v15-latin-200italic.woff2') format('woff2');
}
/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../font/Montserrat/montserrat-v15-latin-300.woff2') format('woff2');
}
/* montserrat-300italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('../font/Montserrat/montserrat-v15-latin-300italic.woff2') format('woff2');
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../font/Montserrat/montserrat-v15-latin-regular.woff2') format('woff2');
}
/* montserrat-italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('../font/Montserrat/montserrat-v15-latin-italic.woff2') format('woff2');
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../font/Montserrat/montserrat-v15-latin-500.woff2') format('woff2');
}
/* montserrat-500italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('../font/Montserrat/montserrat-v15-latin-500italic.woff2') format('woff2');
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../font/Montserrat/montserrat-v15-latin-600.woff2') format('woff2');
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../font/Montserrat/montserrat-v15-latin-700.woff2') format('woff2');
}

/* montserrat-600italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('../font/Montserrat/montserrat-v15-latin-600italic.woff2') format('woff2');
}
/* montserrat-700italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('../font/Montserrat/montserrat-v15-latin-700italic.woff2') format('woff2');
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../font/Montserrat/montserrat-v15-latin-800.woff2') format('woff2');
}
/* montserrat-800italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: url('../font/Montserrat/montserrat-v15-latin-800italic.woff2') format('woff2');
}
/* montserrat-900 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('../font/Montserrat/montserrat-v15-latin-900.woff2') format('woff2');
}
/* montserrat-900italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: url('../font/Montserrat/montserrat-v15-latin-900italic.woff2') format('woff2');
}
