@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.playlistCard {
  display: block;
  position: relative;
  width: 100%;
  height: 130px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);

  @include media-breakpoint-up(xxl) {
    height: 150px;
  }
}

.playlistCard .playlistThumbnail {
  width: 100%;
  height: 100%;

  .image {
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    .shadow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 30%);
    }
    .playlistCategory {
      padding: 10px 15px;
      height: 16px;
      box-sizing: content-box;
      position: relative;
    }
  }
}

.playlistCard .playlistTitle {
  line-height: 19px;
  white-space: pre-wrap;
  color: white;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  margin: 10px 15px;
  text-transform: uppercase;
  z-index: 1;
}

.playlistCard .overlayCollection {
  display: block;
  display: block;
  background: linear-gradient(0deg, rgba(19, 19, 19, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  min-height: 80%;
  min-width: 100%;
}
