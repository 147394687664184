@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.modal {
  background-color: #2f2f2f;
  padding: 0px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  color: white;
  margin: 0 auto;
  max-width: 600px;

  @include media-breakpoint-down(sm) {
    max-width: 480px;
  }
}

.modal .content {
  padding: 2em;
  @include media-breakpoint-down(sm) {
    margin: 0.6em;
    padding: 0.5em;
  }

  .header {
    padding: 0em 2em;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    color: white;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    margin-bottom: 10px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 0.5em;
      margin-top: 20px;
    }
  }

  .loader {
    padding: 1em 2em;
    text-align: center;
    color: white;
    line-height: 19px;
    font-size: 15px;
    margin-bottom: 2em;
  }

  .description {
    font-family: 'Lato', Helvetica, Arial, serif;
    color: #fff;
    font-size: 15px;
    padding-bottom: 20px;
    line-height: 20px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button {
    display: inline-block;
  }
}

.containerInput {
  margin: 5px 5px;
  border-radius: 4px;
  max-height: 40vh;
  overflow-y: scroll;

  &.disableScroll {
    overflow: hidden;
  }
}

.emptyContainer {
  color: #747474;
  text-align: center;
  font-size: 14px;
  margin: 20px auto;
}
.containerTagsAdded {
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  border-radius: 4px;
  /* min-height: 35px; */
  padding: 4px 5px;
  flex-wrap: wrap;
  align-items: center;

  input {
    background-color: transparent;
  }
}

.createTagButton {
  display: flex;
  align-items: center;
  margin-right: 10px;
}