@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.modal {
  width: 75%;
  max-width: 620px;
  @include media-breakpoint-down(sm) {
    width: 90%;
  }
}

.modal .title {
  align-self: flex-end;
  line-height: 20px;
  min-height: 26px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  color: var(--pink-swan);
  font-family: 'Lato', Helvetica, Arial, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
}

.modal .content {
  padding: 2em;
  text-align: center;

  @include media-breakpoint-down(sm) {
    margin: 0.6em;
    padding: 0.5em;
  }
}

.modal .header {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  font-family: 'Montserrat', Helvetica, Arial, serif;
}

.modal .lineDivider {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  margin-top: 5px;
  margin-bottom: 5px;
}

.switch_container {
  display: flex;
  margin: 1em 0 0;
  line-height: 1.2;
  align-items: center;
  & + .switch_container {
    margin-top: 1em;
  }
}

.bluetooth_container {
  display: flex;
  margin: 21px 0;
  line-height: 1.2;
  align-items: center;
  & + .bluetooth_container {
    margin-top: 0;
  }

  .bluetooth_label {
    flex: 1;
    color: rgba(185, 185, 185, 1);
    font-family: 'Lato', Helvetica, Arial, serif;
    text-transform: none;
    font-weight: normal;
    margin-right: 20px;

    cursor: pointer;
    .span0 {
      font: normal normal normal 18px/19px Lato;
      line-height: 26px;
      text-align: left;
      color: #b9b9b9;
    }
    .span1 {
      text-align: left;
      font: normal normal normal 16px/19px Lato;
      letter-spacing: 0px;
      color: #b9b9b9;
    }
  }

  .iconBluetooth {
    width: 20px;
    height: 33px;
  }
  img {
    display: 'block';
    text-align: 'center';
  }
}

.switch_label {
  flex: 1;
  color: rgba(185, 185, 185, 1);
  font-family: 'Lato', Helvetica, Arial, serif;
  text-transform: none;
  font-weight: normal;
  margin-right: 20px;

  cursor: pointer;
  .span0 {
    font: normal normal normal 18px/19px Lato;
    line-height: 26px;
    text-align: left;
    color: #b9b9b9;
  }
  .span1 {
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #b9b9b9;
  }
}

.medias_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1.5em;
  gap: 1em;
}

@media (max-width: 768px) {
  .medias_container {
    grid-template-columns: repeat(1, 1fr);
  }
  .media {
    margin-bottom: 0;
  }
}

.stateoffline {
  display: flex;
}

.download {
  background-color: #1d8cb9;
  border-radius: 999px;
  color: #e5e5e5;
  margin-bottom: 15px;
  margin-top: 8px;
  text-align: center;
  height: 25px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.cola {
  background-color: #6bad12;
  border-radius: 999px;
  color: #e5e5e5;
  margin-bottom: 15px;
  margin-top: 8px;
  text-align: center;
  height: 25px;
  display: flex;
  align-items: center;
  padding: 10px;
}
