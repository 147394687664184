.container {
  background-image: url('@/img/surveys/bg-nps-web-modal.png');
  background-position: center top; 
  background-size: 100% 100px;
  background-repeat: no-repeat;
  background-color: #2F2F2F;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: fit-content;
  min-width: 385px;

  padding-bottom: 50px;

  .title {
    font-weight: bold;
    font-family: Montserrat;
    text-align: center;
    color: white;
    line-height: 1.3;

    max-width: 400px;
  }

  .subtitle {
    font-family: Lato;
    text-align: center;
    color: #9C9C9C;
    line-height: 1.3;

    max-width: 210px;
  }

  .numbers {
    display: flex;
    gap: 10px;

    .number {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #595959;
      width: 30px;
      height: 30px;

      outline: none;
      border: none;
      border-radius: 50%;

      cursor: pointer;

      .score {
        font: normal normal normal 14px/17px Lato;
        color: #B9B9B9;
      }
    }
  }

  .number.active {
    background-color: #ff7900;

    .score {
      color: white;
    }

    cursor: inherit;
  }

  .feedbackContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    width: 100%;
    flex-grow: 1;

    .question {
      font: normal normal bold 15px/19px Montserrat;
      text-align: center;
      color: white;

      max-width: 400px;
    }

    .feedback {
      width: 100%;
      flex-grow: 1;

      font: normal normal normal 14px/17px Lato;
      background-color: #3C3B3B;

      border: none;
      border-radius: 5px;

      padding: 10px;
      color: white;

      resize: none;
    }
  }
}

.container.initial {
  gap: 25px;
  font-size: 15px;
}

.container.finished {
  gap: 10px;
  font-size: 14px;

  .title {
    font-size: 18px;
  }

  .subtitle {
    font-size: 16px;
  }
}