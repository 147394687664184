@import '../../../../../../css/variables';
@import '../../../../../../css/breakpoints';

.container {
  position: relative;

  display: flex;

  overflow: hidden;

  background-color: #262626;

  // DEBUG
  // @include media-breakpoint-down(lg) {
  //   background-color: white;
  // }

  // @include media-breakpoint-down(md) {
  //   background-color: yellow;
  // }

  // @include media-breakpoint-down(md) {
  //   background-color: orange;
  // }

  // @include media-breakpoint-down(sm) {
  //   background-color: red;
  // }

  // @include media-breakpoint-up(xl) {
  //   background-color: green;
  // }

  // @include media-breakpoint-up(xxl) {
  //   background-color: blue;
  // }

  // @include media-breakpoint-up(xxxl) {
  //   background-color: darkblue;
  // }

  // @include media-breakpoint-up(xxxxl) {
  //   background-color: black;
  // }

  .main {
    flex: 1;

    @include media-breakpoint-down(sm) {
      flex: 2;
    }

    @include media-breakpoint-up(xl) {
      flex: 3;
    }

    @include media-breakpoint-up(xxxl) {
      flex: 2;
    }

    @include media-breakpoint-up(xxxxl) {
      flex: 1;
    }

    .points {
      display: flex;
      gap: 4px;

      position: absolute;
      bottom: 8px;
      left: 8px;
    }

    .materials {
      display: flex;
      gap: 7px;

      position: absolute;
      bottom: 8px;
      right: 8px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 15px;

    flex: 2;
    min-width: 100px;

    .primaryContent {
      display: flex;
      flex-direction: column;
      gap: 7px;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
      }

      span.title,
      span.classTitle {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: bold;

        color: white;
      }
    }

    .secondaryContent {
      .trainingLevel {
        display: flex;
        gap: 5px;
      }
    }
  }

  .mind {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
  }
}