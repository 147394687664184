@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.trainingClassList {
  display: flex;
  flex-wrap: wrap;
  margin: 1% -1%;

  .trainingClassListItem {
    display: inline-block;
    position: relative;
    -webkit-user-drag: none;
    box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 50%);
    margin: 0.5%;
    width: 18.5%;

    @include media-breakpoint-up(xxxxl) {
      margin: 0.5%;
      width: 18.5%;
    }
    @include media-breakpoint-down(xxxl) {
      width: 23%;
      margin:1%;
    }
    @include media-breakpoint-down(xxl) {
      width: 31.2%;
      margin: 1%;
    }
    @include media-breakpoint-down(md) {
      width: 47%;
      margin: 1.5%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 0;
      margin-bottom: 1em;
    }
  }
}
.bg {
  background-color: #262626;
}
.emptyTrainingClasses {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 10vh;
  color: #9c9c9c;
  font-size: 16px;
  line-height: 19px;
}


