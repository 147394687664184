// Each .round has to be 33% of the row, and if it doesn't fit wrap to the next row
@import '../../../../css/variables';
@import '../../../../css/breakpoints';
.titleCenter {
  flex: 0 0 auto;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  h3 {
    text-align: center;
    font: normal normal bold 15px/18px Lato;
    letter-spacing: 1.2px;
    color: #b9b9b9;
  }
}

.trainingFlex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.round {
  flex: 1 0 0%;
  min-width: 33%;
  padding: 1rem;
  transition: all 0.3s ease;

  h4 {
    color: #b9b9b9;
    margin-bottom: 0.5rem;
    text-align: left;
    font: normal normal normal 14px/17px Lato;
  }
}

.roundMethods {
  background-color: #252525;
}

.roundMethod {
  text-align: left;
  font: normal normal normal 14px/17px Lato;
  background-color: #1d1d1d;
  color: white;
  margin-bottom: 1px;
  padding: 18px 24px;
}

.methodSeconds {
  font: normal normal normal 14px/17px Lato;
  color: #b9b9b9;
  margin-right: 0.5rem;
}

// MEDIA QUERY FOR MOBILE
@media (max-width: 768px) {
  .round {
    flex: 0 0 auto;
    width: 100%;
  }
}

.displayRounds {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
  .displayRound {
    font-size: 13px;
    margin-top: 20px;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 10px;
    margin-left: 20px;

    .roundTitle {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      font-size: 11px;
    }
    .columnsRound {
      display: flex;
      gap: 10px;
      margin-right: 10px;
    }

    .roundExercises {
      overflow: hidden;
      .lineExercise {
        display: flex;
        justify-content: space-between;

        background-color: #1d1d1d;
        width: 100%;
        height: 100%;

        line-height: 35px;
        border-bottom: 1px solid #252525;

        &.bigger {
          padding: 6px 0;
        }

        .description {
          display: flex;
          gap: 10px;

          float: left;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          padding: 0 10px;

          span {
            white-space: nowrap;
          }
        }
        .rounds {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 15px;
        }

        .previous,
        .today,
        .totals {
          width: 20px;
          font-size: 20px;
          font-weight: 700;
          color: white;
          font-family: 'Montserrat';
          display: flex;
          justify-content: center;
        }

        .previous,
        .today {
          margin: 0 12px;
        }
        .totals {
          margin: 0 20px;
        }

        .method {
          text-transform: capitalize;
          font-weight: 500;
          color: white;
        }
        .cyclingMethod {
          font-weight: 500;
          color: white;
        }
      }
    }
  }
}
