.zonesContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;

  .zonesRowContainer {
    position: relative;
    width: 100%;
    height: 33px;

    .percentageProgress {
      height: 33px;
    }

    .content {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 33px;
      padding: 0 10px;
      font: normal normal normal 15px/19px Lato;
      letter-spacing: 0px;
      color: #ffffff;

      .percent,
      .name,
      .time {
        flex: 1;
      }

      .name {
        text-align: center;
      }
      .time {
        text-align: right;
      }
    }

    .shadow {
      height: 33px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
