.container {
  display: flex;
  width: 100%;
  background-color: var(--woodsmoke);
  margin-bottom: 20px;
  border-radius: 5px;
}
.avatar {
  margin: 1em 0.5em 1em 1em;
}
.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;

  .textarea {
    height: 70px;
    background: transparent;
    border: none;
    padding: 1em 0.5em;
    margin: 1em;

    color: var(--pink-swan);
    font-family: 'Lato', Helvetica, Arial, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;

    letter-spacing: 0px;
    line-height: 22px;
    min-height: 23px;
    min-width: 175px;
    white-space: wrap;
    resize: none;
    border-bottom: 1px solid transparent;
    transition: all 0.25s ease-in-out;

    &:focus {
      border-bottom: 1px solid #666;
      transition: all 0.25s ease-in-out;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}

.buttons {
  padding: 0 1em 1.5em 1em;

  button {
    padding: 0.5em 1em;
    background-color: #ff7900;
    font-weight: 600;
    font-size: 13px;
    font-family: 'Lato', Helvetica, Arial, serif;
    text-transform: uppercase;
    color: #fff;
    border: none;
    border-radius: 2px;
    margin-right: 20px;
    transition: all 0.25s ease-in-out;

    &:disabled {
      background-color: #2f2f2f;
      color: #666;
      transition: all 0.25s ease-in-out;
    }
  }
}

.containerButtons {
  display: flex;
  gap: 5px;
  padding-bottom: 1em;
  padding-left: 1em;
}

.uncommentableContainer {
  padding-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  .content {
    text-align: center;
    line-height: 22px;
  }
  .buttonClasses {
    border-radius: 3px;
    width: 200px;
    margin-bottom: 10px;
  }
}
