@import '../../../../css/variables.scss';
@import '../../../../css/breakpoints.scss';

.containerLogin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  max-width: 450px;
  transform: translateY(-50%) translateX(-50%);

  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: 100%;
    padding: 20px;
  }

  .titleLogin {
    font-size: 24px;
    line-height: 1.25;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    font-weight: bold;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  a {
    color: #747474;
  }
}

.loginForm {
  .field,
  .submit {
    margin-bottom: 20px;

    input[type='submit'] {
      background-color: var(--orange);
      font-family: 'Montserrat', Helvetica, Arial, serif;
      font-weight: 500;
      height: 45px;
      margin: 0;
    }

    input[type='submit']:hover {
      background-color: #f79233;
    }
  }

  .field + .submit {
    margin-top: 1em;
  }

  button[type='submit'] {
    width: 100%;
  }

  input {
    width: 100%;
    height: 45px;
    color: white;
    padding-left: 25px;
  }

  input[error='true'] {
    outline: 1px solid var(--salmon-pearl);
  }

  input[error='true']:focus {
    outline: 1px solid var(--salmon-pearl);
    box-shadow: 0 0 4px var(--salmon-pearl);
  }

  input::placeholder {
    color: #b9b9b9;
    opacity: 1;
  }
}

.resetDefaultElectron {
  > button {
    all: unset;
    bottom: 30px;
    right: 30px;
    color: #bbbbbb;
    position: fixed;
    z-index: 100;
    font-size: 14px;
  }

  > button:hover {
    color: white;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 360px;
}

.titleModal {
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
}

.description {
  margin: 16px 0;
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal normal 15px/21px Lato;
  letter-spacing: 0px;
  color: #c9c9c9;
}

span.orangeText {
  color: var(--orange);
}

.errorMessage {
  color: var(--salmon-pearl);
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 12px;
}

.errorPopup {
  background-color: var(--salmon-pearl);
  padding: 10px 30px;
  border-radius: 3px;
  margin-bottom: 20px;

  p {
    color: white;
    margin: 0;
    line-height: 1.5;
  }
}

.loadingIndicator {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
}
.avatar {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.avatar img {
  border-radius: 50% !important;
  max-width: 100%;
  height: auto;
}

.lineContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.line {
  flex: 1;
  border: none;
  border-top: 1px solid #747474; /* Color y grosor de la línea */
  margin: 20px 0px; /* Espacio alrededor de la línea */
}

.text {
  white-space: nowrap; /* Evita que el texto se rompa en varias líneas */
  font-size: 12px; /* Ajusta el tamaño del texto según sea necesario */
  font-weight: bold; /* Estilo del texto */
  color: #747474;
}

.containerProviders {
  margin-bottom: 50px;
}
