.tagContainer {
  background: #fafafa;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  color: #000000d9;
  display: inline-block;
  font-feature-settings: 'tnum';
  font-size: 13px;
  font-variant: tabular-nums;
  height: auto;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  opacity: 1;
  padding: 0 7px;
  transition: all 0.3s;
  white-space: nowrap;
}

// Colors
.tagContainer.tag-blue {
  color: white;
  background: #1d8cb9;
  border-color: #1d8cb9;
}