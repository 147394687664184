$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: 1025px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  xxxxl: 2000px,
);

$navigation-width: 289px;
$topbar-height: 65px;

$page-vertical-padding: 10px;
$page-horizontal-padding: 34px;
$page-vertical-padding-sm: 34px;
$page-horizontal-padding-sm: 20px;

$defaultTagColor: white;
$complementaryTagColor: #151515;

$red: #CC3841;
$orange: #F08320;
$yellow: #FCD900;
$green: #69AE00;
$blue: #3A9CC4;
$blackblue: #2051D0;
$violet:  #8E58F5;
$purple:  #D97495;
$pink:  #D6AB96;
$salmon:  #DDDDDD;