@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.levelsheader {
  width: 100%;
  height: 225px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  @include media-breakpoint-down(lg) {
    height: 170px;
  }

  img {
    width: 280px;
    padding-left: 36px;
    position: absolute;
    top: 40%;
  }
}

.levels {
  display: flex;
  padding-left: 36px;
  background:#070707;
  min-height: 40px;
  overflow: auto;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.smalllevels {
  display: none;

  select {
    border: none;
    padding: 12px 36px;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    width: 100%;
    background-position: calc(100% - 20px);
    box-shadow: none;
    font-size: 13px;
    font-weight: 600;
    color: white;
    letter-spacing: 1px;
    @include media-breakpoint-down(sm) {
      padding: 12px 20px;
    }
  }

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.level {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 30px;
  color: white;
  cursor: pointer;
  font-size: 13px;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 1px;
  white-space: nowrap;
}

.levelinfo {
  display: flex;
  flex-direction: column;
  min-height: 90px;
  padding: 25px 36px 25px 36px;
  border-top: 1px solid #343434;

  @include media-breakpoint-down(sm) {
    padding: 20px 36px 25px 20px;
  }
}

.cycling {
  background-image: url(/src/img/bestcycling-header.jpg);
}
.training {
  background-image: url(/src/img/besttraining-header.jpg);
}
.running {
  background-image: url(/src/img/bestrunning-header.jpg);
}
.balance {
  background-image: url(/src/img/bestbalance-header.jpg);
}
.walking {
  background-image: url(/src/img/bestwalking-header.jpg);
}
.mind {
  background-image: url(/src/img/bestmind-header.jpg);
}

.leveltitle {
  font-weight: bold;
  font-size: 15px;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-weight: 700;
  line-height: 20px;
}

.description {
  color: #9c9c9c;
  font-size: 14px;
  line-height: 19px;
}

.subtitle {
  font-size: 24px;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-weight: 700;
  line-height: 36px;
  @include media-breakpoint-down(md) {
    font-size: 20px;
    line-height: 28px;
  }
}
