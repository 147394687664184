@import '../../../../css/variables';
@import '../../../../css/breakpoints';

button.fav {
  border: none;
  background: transparent;
  padding: 0;
}

button.fav.active .border {
  border: 2px solid var(--orange);
}

.border {
  border: 2px solid var(--celeste);
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
}

.icon {
  height: 20px;
  width: 20px;
}
