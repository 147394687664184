.modal {
  background: #2f2f2f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000081;
  width: 31rem;

  .title {
    text-align: center;
    font: normal normal bold 24px/29px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
  }

  .description {
    text-align: center;
    font: normal normal normal 15px/21px Lato;
    letter-spacing: 0px;
    color: #b2b2b1;
    margin-top: 1rem;
    margin-bottom: 1.75rem;
  }

  footer {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}
