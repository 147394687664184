.toast {
  margin-top: 15px;
  padding: 20px;
  background-color: #f2f2f2;
  height: 50px;
  align-items: center;
  opacity: 1;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.398);
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
  p {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: black;
    line-height: 18px;
    margin-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.toastsContainer {
  position: fixed;
  bottom: 20px;
  left: 36px;
  min-width: 381px;
  max-width: 900px;
  z-index: 1500;
}

.close {
  position: absolute;
  top: 18px;
  right: 20px;
  height: 15px;
  transform: rotate(180deg);
  width: 15px;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent url('../../../../img/icon-close-black.svg') 0% 0% no-repeat padding-box;
  background-size: cover;
  border-radius: 33px;
  opacity: 1;
}

.toast-enter {
  opacity: 0;
  transform: translateY(+100px);
}
.toast-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all 300ms ease-in-out;
}
.toast-exit {
  opacity: 1;
}
.toast-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.error {
  background-color: #ffc4c6;
  border-left: 8px solid #f13b46;
}

.warn {
  background-color: #f2d8a2;
  border-left: 8px solid #d99d21;
}

.success {
  background-color: #ffffff;
  border-left: 8px solid #21d99d;
}

.info {
  background-color: #ffffff;
  border-left: 8px solid #219fd9;
}

.toast:fullscreen {
  display: none;
}
