@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.share {
  border: 2px solid var(--celeste);
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 40px;
  width: 40px;
  padding: 10px;
  justify-content: center;
  background: transparent;
}

.share .icon {
  height: 20px;
  width: 20px;
  top: -1px;
  position: relative;
}
