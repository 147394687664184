@import '../../../../css/variables.scss';
@import '../../../../css/breakpoints.scss';

.container {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;

  text-align: center;
  border: 1px solid #2F2F2F;

  padding: 20px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}
