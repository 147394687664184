@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.header {
  font-family: 'Montserrat', Helvetica, Arial, serif;
  color: white;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  margin-bottom: 10px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 0.5em;
    margin-top: 20px;
  }

  .description {
    font-family: 'Lato', Helvetica, Arial, serif;
    color: #fff;
    font-size: 15px;
    padding-bottom: 20px;
    line-height: 20px;
    text-align: center;
    width: 70%;
    margin: 0 auto;
  }

  .buttons {
    margin-top: 20px;
    text-align: center;
  }

  .color {
    background-color: darkmagenta;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }

}

.transparent {
  background-color: transparent;
  border: 0;
}

.chevronDown{
  width: auto;
  height: 10px;
  margin-top: 1.5px;
  padding: 1px;
}