@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.nutritionSearch {
  .header {
    margin-bottom: 20px;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: row;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }
}
.card {
  width: 346px;
  height: 189px;
  min-height: 170px;

  @include media-breakpoint-down(xxl) {
    width: 275px;
    min-height: 170px;
  }

  .nutritionSearchModal {
    .searchContainer {
      width: 100% !important;
      display: block;
    }
  }
}
