@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  overflow: hidden;

  .exercise {
    position: relative;

    border: none;
    outline: none;

    background-color: transparent;
    padding: 0;

    width: 345px;
    height: 277px;

    @include media-breakpoint-up(xxl) {
      width: 367px;
      height: 294px;
    }

    .background {
      object-fit: cover;

      width: 100%;
      height: 100%;
    }

    .playIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      justify-content: center;
      align-items: center;

      width: 40px;
      height: 40px;

      background-color: #ebd9db;
      border-radius: 50%;

      & * {
        margin-left: 6px;
      }
    }
  }

  .title {
    text-transform: capitalize;
  }
}
