@import '@/css/variables';
@import '@/css/breakpoints';

.container {
  position: fixed;
  bottom: 0;

  display: flex;
  flex-direction: column;
  gap: 25px;

  background-color: #262626;
  width: 100vw;
  padding: 30px;

  // TODO: navigator has default z-index to 20, check if can be removed to remove this
  z-index: 21; 

  @include media-breakpoint-up(md) {
    justify-content: space-between;
    align-items: center;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @include media-breakpoint-up(lg) {
    justify-content: center;
    gap: 150px;
  }
}

.container p.description {
  color: #9C9C9C;
  line-height: 1.4;
  font-size: 13px;

  & .link {
    color: #ff7900;
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    max-width: 60%;
  }
}

.container .buttons {
  display: flex;
  gap: 15px;
  
  & * {
    flex: 1;
  }

  & .primary {
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      flex-basis: 600px;
    }
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row-reverse;
    align-items: center;
  }
}