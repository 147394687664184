@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.media {
  display: flex;
  gap: 1em;
  flex-direction: column;
  @include media-breakpoint-down(md) {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
  }

  .stateoffline {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
    font-size: 14px;
    margin-top: 10px;
    display: inline-block;
    color: #fff;

    &.cola {
      background-color: #69ae00;
      padding-left: 10px;
      padding-right: 10px;
    }

    &.download {
      background-color: #1d8cb9;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.media_thumbnail {
  align-items: center;
  align-self: flex-start;

  height: 100%;
  width: 100%;
  position: relative;
  margin: 0;

  @include media-breakpoint-down(md) {
    flex: 2;
  }

  .media_play_icon {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 50px;
      height: 50px;
      opacity: 0.8;

      @include media-breakpoint-down(sm) {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.media_info {
  @include media-breakpoint-down(md) {
    flex: 3;
  }
}

.media_title,
.media_size {
  font-family: 'Lato', Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 600;
}

.media_title {
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
  line-height: 19px;
}
.media_size {
  color: var(--pink-swan);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
}

@media (max-width: 767px) {
  .media {
    margin: 0;
  }
}
