.container {
  position: fixed;
  top: 11px;
  right: 200px;
  background-color: #1d8cb9;
  z-index: 10000;
  border-radius: 5px;
  color: white;
  padding: 2px 10px;
  text-align: center;
  line-height: 1.3;
  font-size: 15px;
  width: auto;
  display: flex;
  align-items: center;
  img {
    width: 40px;
    margin-right: 5px;
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .container {
    right: 90px;
  }
}
