@import '../../../../css/variables';

.colorContainer {
  position: relative;

  border: none;
  outline: none;

  * {
    pointer-events: none;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
}

.color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-bottom: -2px;
  display: inline-block;
  text-align: center;
  padding-top: 2px;
}
.check{
  margin-top: -1px;
}

.red{
  background-color: $red;
  color: $defaultTagColor;
}
.orange{
  background-color: $orange;
  color: $defaultTagColor;
}
.yellow{
  background-color: $yellow;
  color: $complementaryTagColor;
}
.green{
  background-color: $green;
  color: $defaultTagColor;
}
.blue{
  background-color: $blue;
  color: $defaultTagColor;
}
.blackblue{
  background-color: $blackblue;
  color: $defaultTagColor;
}
.violet{
  background-color: $violet;
  color: $defaultTagColor;
}
.purple{
  background-color: $purple;
  color: $defaultTagColor;
}
.pink{
  background-color: $pink;
  color: $complementaryTagColor;
}
.salmon{
  background-color: $salmon;
  color: $complementaryTagColor;
;
}

.big{
  width: 24px;
  height: 24px;
  &:hover{
    filter: brightness(0.90);
    cursor:pointer;
  }
}