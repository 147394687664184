@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.fav {
  border: none;
  background: transparent;
  padding: 0;
}

.fav.active {
  .border {
    border: 2px solid var(--orange);
  }
}

.fav:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.fav .border {
  padding: 0;
  border: 2px solid var(--celeste);
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
}

.fav .icon {
  height: 20px;
  width: 20px;
}