@import '../../../../css/variables';
@import '../../../../css/breakpoints';

%button {
  align-items: center;
  background-color: var(--heat-wave);
  border-radius: 3px;
  height: 50px;
  overflow: hidden;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 17px;
  color: white;
  text-transform: uppercase;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  border: none;
  font-weight: 600;
  margin-bottom: 20px;
}

.foodRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 25px;
  border-bottom: 1px solid #2e2e2e;
  .label {
    font-weight: bold;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    color: white;
  }
}

.title {
  align-self: flex-end;
  line-height: 20px;
  min-height: 26px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  color: var(--pink-swan);
  font-family: 'Lato', Helvetica, Arial, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
}

.header {
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 24px;
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
  text-align: center;
  line-height: 28px;
  margin-top: 10px;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;
  }
}

.emptyItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 25px;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  color: #B9B9B9;
  font-size: 14px;
  line-height: 21px;
  margin-top:15px;
}

.emptyItemsQuote {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 25px;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  color: #747474;
  font-size: 14px;
  line-height: 21px;
}

.default {
  .confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    .buttons {
      display: flex;
      flex-direction: row;

      .button {
        @extend %button;
        min-width: 140px;
        margin-top: 20px;
      }
      .objectives {
        background-color: #242424;
        margin: 5px 0;
      }
      .objectives:hover {
        background-color: var(--heat-wave);
      }
      .button:disabled {
        background: #343434;
        color: #b9b9b9;
      }
    }
  }

  .modern_radio_container {
    display: flex;
    cursor: pointer;
    user-select: none;
    align-items: center;

    .checkBoxCustom {
      margin-right: 15px;
    }
  }

  .customCheckBoxContainer {
    display: flex;

    .labelCheckbox {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: break-spaces;
      text-align: start;
      color: #b9b9b9;
      font-family: 'Lato', Helvetica, Arial, serif;
      font-size: 14px;
      text-shadow: 1px 1px rgb(0 0 0 / 50%);
      letter-spacing: normal;
      word-spacing: normal;
      line-height: normal;
      text-transform: none;
      text-indent: 0px;
      text-rendering: auto;
    }

    input[type='checkbox'] {
      display: none;
    }

    .square {
      position: relative;
      display: block;
      width: 21px;
      height: 21px;
      border: 2px solid rgb(95, 100, 111);
      cursor: pointer;
      margin-right: 15px;
      border-radius: 1px;
    }

    .checked {
      background-image: url(/src/img/nutrition/check-food.svg);
      background-repeat: no-repeat;
      border: 2px solid #ff7900;
      width: 20px;
      height: 20px;
      background-position: center;
      margin-right: 17px;
    }

    .unchecked {
      position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 17px;
    vertical-align: middle;
    background-color: inherit;
    color: #fd7922;
    border: 2px solid #666;
    border-radius: 2px;
    }
  }

  .listData {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 400px);
    min-height: calc(100vh - 400px);
    overflow: hidden;
    margin: 0 -25px 25px -25px;
    padding: 0 25px;
    background-color: #151515;

    .listDataScroll {
      overflow: scroll;
    }
  }

  .search {
    display: flex;
    columns: 2;
    justify-content: flex-end;
    padding: 10px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #3a3a3a;

    @include media-breakpoint-down(md) {
      width: 48%;
    }

    @include media-breakpoint-down(sm) {
      width: 10%;
    }

    .searchInput {
      width: 100%;
      input[type='input'] {
        background-color: transparent;
        border: none;
        height: 25px;
        outline: 0;
        color: #B9B9B9;
        font-family: "Lato", Arial, Helvetica, sans-serif;
      }
    }

    .button_search {
      display: flex;
      height: 25px;
      background-color: #262626;
      border: none;
      padding: 0 20px 0 0;
      border-radius: 2px;
      align-items: center;

      @include media-breakpoint-down(sm) {
        display: flex;
        width: 48px;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.select {
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: #262626;

  .search {
    padding: 10px 0px 5px 0px;
    margin: 5px 15px 15px 10px;
  }

  .confirmation {
    background-color: #262626;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: 90%;
      margin: 15px 0;
    }
    .buttons {
      display: flex;
      flex-direction: row;

      .button {
        @extend %button;
        min-width: 140px;
        margin-top: 20px;
      }
      .objectives {
        background-color: #242424;
        margin: 5px 0;
      }
      .objectives:hover {
        background-color: var(--heat-wave);
      }
      .button:disabled {
        background: #343434;
        color: #b9b9b9;
      }
    }
  }

  .modern_radio_container {
    width: 100%;
    display: flex;
    cursor: pointer;
    user-select: none;
    align-items: center;

    .checkBoxCustom {
      margin-right: 15px;
    }
  }

  .customCheckBoxContainer {
    display: flex;
    width: 100%;

    .labelCheckbox {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: break-spaces;
      display: flex;
      width: 100%;
      text-align: start;
      color: #b9b9b9;
      font-family: 'Lato', Helvetica, Arial, serif;
      width: 100%;
      font-size: 14px;
      text-shadow: 1px 1px rgb(0 0 0 / 50%);
      letter-spacing: normal;
      word-spacing: normal;
      line-height: normal;
      text-transform: none;
      text-indent: 0px;
      text-rendering: auto;
    }

    .labelChecked {
      color: #ff7900 !important;
    }

    input[type='checkbox'] {
      display: none;
    }

    .check {
      width: 20px;
      height: 20px;
    }

    .checked {
      color: #ff7900;
    }

    .unchecked {
      color: #a7a7a7;
    }
  }

  .listData {
    display: flex;
    flex-direction: column;
    max-height: 150px;
    min-height: 150px;
    overflow: hidden;
    padding: 0;
    background-color: #1d1d1d;

    .listDataScroll {
      overflow: scroll;
    }
  }
  .search {
    display: flex;
    columns: 2;
    justify-content: flex-end;
    align-items: baseline;
    padding: 10px 5px 6px 5px;
    border-bottom: 1px solid #3a3a3a;
    background-color: #262626;

    @include media-breakpoint-down(md) {
      width: 48%;
    }

    @include media-breakpoint-down(sm) {
      width: 10%;
    }

    .searchInput {
      font-size: 12px;
      width: 100%;
      input[type='input'] {
        background-color: transparent;
        border: none;
        height: 25px;
        outline: 0;
        color: #b9b9b9;
        margin-bottom: -3px;
        font-family: "Lato", Arial, Helvetica, sans-serif;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #6d6d6d;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #b9b9b9;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #b9b9b9;
        }
      }
    }

    .button_search {
      display: flex;
      height: 25px;
      background-color: #262626;
      border: none;
      padding: 0 10px 0 0px;
      align-items: center;

      @include media-breakpoint-down(sm) {
        display: flex;
        width: 48px;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 15px;
        height: 15px;
      }
    }
  }
}
