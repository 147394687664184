:root {
  --celeste: rgba(209, 209, 209, 1);
  --orange: #ff7900;
  --eerie-black: rgba(31, 30, 30, 1);
  --pink-swan: rgba(185, 185, 185, 1);
  --star-dust: rgba(156, 156, 156, 1);
  --licorice: rgba(21, 21, 21, 1);
  --salmon-pearl: rgba(241, 59, 70, 1);
  --masala: rgba(60, 60, 60, 1);
  --shark: rgba(38, 38, 38, 1);
  --heat-wave: rgba(255, 121, 0, 1);
  --quick-silver: rgba(168, 168, 168, 1);
  --woodsmoke: rgba(13, 13, 13, 1);
}

* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;

  // A way to centralize every component class that should block the body scroll
  &:has(.body_scroll_blocked) {
    overflow: hidden;
  }
}

html {
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Lato', helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  padding: 0px;
  color: #aaaaaa;
  position: relative;
  background-color: var(--licorice);
}

a,
img {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}

a {
  color: #aaaaaa;
  text-decoration: none;
  font-weight: 400;
  font-size: 13px;
  transition: color 0.3s ease;
}

a:hover {
  color: #ffffff;
}

img {
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: #ffffff;
  text-transform: initial;
}

button:hover {
  cursor: pointer;
}

/* visto reflejado en las cajas de los filtros ( parece un componente ) */
select {
  font-size: 14px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  padding-right: 30px;
  cursor: pointer;
  -webkit-appearance: none;
  font-family: 'Lato', Helvetica, Arial, serif;
  width: 100%;
  padding: 10px;
  color: #b9b9b9;
  border: 1px solid #343434;
  border-radius: 3px;
  margin-right: 20px;
  background: url(/src/img/dropdown-arrow@2x.png) no-repeat;
  background-size: 17px;
  background-position: calc(100% - 10px);
  box-shadow: none;
}

// /* Ocultamos la barra de desplazamiento */
::-webkit-scrollbar {
  display: none;
}

/* Player CSS */
#app,
#fullscreen {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding-top: 0px;
  padding-left: 0px;
}

/* Login Screen */
#form_login {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../img/body_start_bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  background-size: cover;
  z-index: 4;
}

input[type='checkbox'] {
  position: absolute;
  overflow: hidden;
  padding: 0;
  border: 0;
  opacity: 0.001;
  z-index: 1;
  vertical-align: top;
  outline: none;
}