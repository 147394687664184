.modal {
  background: #252526 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000081;
  width: 563px;
  padding: 0;
  min-height: 200px;

  .header {
    display: flex;
    justify-content: center;
    margin-bottom: 22px;
    padding: 45px 45px 0px 45px;
  }
  .back {
    width: 11px;
    height: 21px;
    position: absolute;
    left: 25px;
    cursor: pointer;
  }

  .title {
    text-align: center;
    font: normal normal normal 14px/20px Lato;
    letter-spacing: 2px;
    color: #b9b9b9;
    text-transform: uppercase;
  }

  .descriptionNoDevices {
    text-align: center;
    font: normal normal normal 14px/18px Lato;
    letter-spacing: 0px;
    color: #747474;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .divider {
    border: 1px solid var(--line-divider);
    border: 1px solid #343434;
    width: auto;
    height: 0px;
    display: flex;
  }

  .footerTitle {
    text-align: center;
    font: normal normal normal 15px/18px Lato;
    letter-spacing: 0px;
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .footerDescription {
    text-align: center;
    font: normal normal normal 14px/18px Lato;
    letter-spacing: 0px;
    color: #747474;
    margin-bottom: 25px;
  }
}
