.genre {
  $bgcolor: #2f2f2f;
  $textcolor: #b9b9b9;
  font: normal normal normal 13px Lato;
  text-align: center;
  margin-right: 5px;
  color: $textcolor;
  gap: 5px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.tag {
  $bgcolor: #2f2f2f;
  $textcolor: #b9b9b9;

  background: $bgcolor 0% 0% no-repeat padding-box;
  border: 1px solid $bgcolor;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font: normal normal normal 12px/14px Lato;
  padding: 8px 12px;
  text-align: center;
  margin-right: 10px;
  color: $textcolor;
  white-space: nowrap;

  display: flex;
  align-items: center;

  &:hover {
    background-color: lighten($bgcolor, 10%);
  }
  &:active {
    border: 1px solid #ff7900;
    background-color: rgba(255, 121, 0, 0.2);
  }

  &.selected {
    border: 1px solid #ff7900;
    background-color: rgba(255, 121, 0, 0.2);
  }
   &.rectangle {
    padding: 7px;
    background-color: black;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    border-radius: 0;
    font-weight: 600;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    color: white;

  }
  &.all .color {
    display: none;
  }

  &.w-color .color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
  }

  &.tag-list {
    padding: 0px 8px;
    color: white;
  }
  &.nohover {
    &:hover {
      background-color: $bgcolor;
    }
    &:active {
      border: 1px solid $bgcolor;
    }
  }
}

.invisible {
  background-color: transparent;
  border: none;
  color: transparent;
}