.modalSmartBike {
  width: 888px;
  padding: 0px;
  background-color: #2f2f2f;

  .container {
    display: flex;
    justify-content: space-between;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 20px;
    transform: rotate(180deg);
    width: 20px;
    border: none;
    padding: 0;
    margin: 0;
    background: url('../../../../img/icon-close.svg');
    background-size: cover;
  }

  .containerHelp {
    width: 50%;
    padding: 45px 45px;
  }

  .title {
    text-align: left;
    font: normal normal bold 24px/29px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 23px;
  }

  .description {
    text-align: left;
    font: normal normal normal 16px/21px Lato;
    letter-spacing: 0px;
    color: #ffffff;
  }

  img {
    width: 50%;
    height: 398px;
  }

  .button {
    margin-top: 10px;
    background: #ff7900 0% 0% no-repeat padding-box;
    border-radius: 4px;
  }
}

.modalBikeConnected {
  background: #2f2f2f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000081;
  width: auto;

  .checkboxChecked {
    height: 41px;
    display: flex;
    justify-content: center;
    margin-bottom: 19px;
  }

  .title {
    text-align: center;
    font: normal normal bold 24px/29px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 23px;
    width: 416px;
  }

  .description,
  .descriptionKnowingFtp {
    text-align: center;
    font: normal normal normal 16px/21px Lato;
    letter-spacing: 0px;
    color: #b2b2b1;
    width: 415px;
  }
  .description {
    margin-bottom: 24px;
  }

  footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .containerButtons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .buttonKnowingFtp {
    width: 13rem;
    height: 2.6rem;
  }
}

.modalConfigFtp {
  background-color: #2f2f2f;
  padding: 45px 115px;
  display: flex;
  align-items: center;

  .title {
    text-align: center;
    font: normal normal bold 24px/29px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 23px;
  }

  .input {
    height: 5rem;
    width: 20rem;
    background: #ffffff1a 0% 0% no-repeat padding-box;
    border-radius: 3px;
    border: none;
    text-align: center;
    font: normal normal normal 38px/46px Lato;
    letter-spacing: 0px;
    color: #b9b9b9;
  }

  .description {
    text-align: center;
    font: normal normal normal 16px/21px Lato;
    letter-spacing: 0px;
    color: #b2b2b1;
    margin-bottom: 1.56rem;
  }

  footer {
    margin-top: 1.5rem;
    display: flex;
    gap: 1.375rem;
  }
  .button {
    width: 9.2rem;
    height: 2.6rem;
  }
}

.modalAllOk {
  background: #2f2f2f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000081;
  width: 28rem;

  .title {
    text-align: center;
    font: normal normal bold 24px/29px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 23px;
  }

  .description {
    font: normal normal normal 16px/21px Lato;
    letter-spacing: 0px;
    color: #b2b2b1;
    text-align: center;
  }

  footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}

.modalNotFtp {
  width: 28rem;
  background: #2f2f2f 0% 0% no-repeat padding-box;

  .title {
    text-align: center;
    font: normal normal bold 24px/29px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 23px;
  }

  .description {
    font: normal normal normal 16px/21px Lato;
    letter-spacing: 0px;
    color: #b2b2b1;
    text-align: center;
    margin-bottom: 1.375rem;
  }

  .divider {
    background: #313131 0% 0% no-repeat padding-box;
    border: 1px solid #343434;
  }
  .containerRow {
    background: #2f2f2f 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: space-between;
    padding: 22px 19px;
    align-items: center;
    border: none;

    .label,
    .value {
      text-align: left;
      font: normal normal normal 14px/17px Lato;
      letter-spacing: 0px;
      color: #b9b9b9;
    }

    .containerValue {
      display: flex;
      gap: 1.8rem;
      align-items: center;

      img {
        height: 12px;
      }
    }
  }

  footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}
