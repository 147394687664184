.errorBoundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  padding: 1rem;

  h1 {
    font-size: 3rem;
    color: var(--orange);
  }

  p {
    margin: 1rem 0;
    color: var(--orange);
  }
}
