@import '../../../css/variables';
@import '../../../css/breakpoints';

.collectionList {
  font-size: 1em;

  .playlistCard {
    display: inline-block;
    position: relative;
    margin: 1%;
    width: 31%;

    @media (min-width: 1600px) {
      margin: 0.5%;
      width: 24%;
    }

    @include media-breakpoint-down(md) {
      width: 47%;
      margin: 1.5%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 0;
      margin-bottom: 1em;
    }
  }
}
