@use "sass:math";

.aspectRatioBox {
  height: 0;
  overflow: hidden;
  padding-top: math.div(9, 16) * 100%;

  position: relative;
}

.aspectRatioBoxInside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
