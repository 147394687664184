.emptySection {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 60px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  max-width: 500px;
}

.sectionHeader {
  font-size: 16px;
  color: white;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.sectionBody{
line-height: 21px;
font-size: 16px;
color: #aaaaaa;
}