@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.container {
  font-size: 13px;
  margin-top: 2%;
  padding: 15px 30px;

  @include media-breakpoint-down(xxl) {
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
  }

  @include media-breakpoint-down(xl) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.colLg15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  width: 50%;
  float: left;

  @include media-breakpoint-up(xl) {
    width: 20%;
    float: left;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  h3 {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 21px;
    @include media-breakpoint-down(xl) {
      margin-top: 20px;
    }
  }

  li {
    margin-bottom: 10px;
  }
}

.legal {
  clear: both;
  display: block;
  text-align: center;
  font-size: 11px;
  opacity: 0.5;
  padding-top: 20px;
  @include media-breakpoint-down(sm) {
    text-align: left;
  }

  span {
    padding: 30px 0 0px 30px;
    @include media-breakpoint-down(sm) {
      padding: 0px 0 20px 0;
      display: block;
    }
  }
  @include media-breakpoint-down(xxl) {
    margin-top: 20px;
  }

  a {
    margin-right: 30px;
    color: #b9b9b9;

    @include media-breakpoint-down(xxl) {
      text-align: left;
      line-height: 1.4rem;
    }

    @include media-breakpoint-down(lg) {
      display: block;
      text-align: left;
      line-height: 1.4rem;
    }
  }
}

.social {
  cursor: pointer;
  img {
    margin-right: 10px;
  }

  @include media-breakpoint-down(xxl) {
    margin-top: 20px;
    order: -1;
  }
}

.copyright {
  order: -1;

  @include media-breakpoint-down(xxl) {
    margin-top: 20px;
    order: 3;
  }

  @include media-breakpoint-down(xl) {
    order: 3;
    margin-top: 20px;
  }
}

.min {
  .legal {
    margin-top: 0 !important;
    span {
      padding-top: 0;
    }
  }
}

.inline {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
}
