.avatarUpload {
  input[type='file'] {
    width: 0;
    height: 0;
    z-index: -1;
    position: absolute;
    overflow: hidden;
    opacity: 0;
  }
  .avatarContainer {
    position: relative;

    .avatar.loading {
      filter: contrast(0.25);
    }

    .avatarLoading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .avatarUploadButton {
    margin: 1em 0;
  }
}
