@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.trainingClassDetails {
  background-color: #151515;
  /* Header */
  header {
    display: flex;
    margin-bottom: 1.25em;
    align-items: flex-start;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      gap: 15px;
    }
  }
  header .avatar {
    width: 4em;
    height: 4em;
    background-color: black;
    border-radius: 50%;
    border: 2px solid #ff7900;
    overflow: hidden;
    margin-right: 1em;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  header .avatar img {
    object-fit: cover;
    width: 100%;
    margin-bottom: -2px;
  }
  header .title {
    flex: 1;
  }
  header .title h2,
  header .title h3 {
    color: white;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 1.5;
  }
  header .title h2 {
    font-family: 'Montserrat', Helvetica, Arial, serif;
    font-size: 32px;
    line-height: 36px;
    @include media-breakpoint-down(xl) {
      font-size: 24px;
      line-height: 28px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  header .title h3 {
    font-family: 'Lato', Helvetica, Arial, serif;
    font-size: 17px;
  }

  header .options {
    display: flex;
    gap: 11px;
  }
}
.title h2:first-of-type {
  margin: 0;
}

.containerTrainerName {
  display: inline-block;
}

.trainingClassDetails {
  h2 {
    letter-spacing: 1.36px;
    line-height: 15px;
    min-height: 23px;
    min-width: 214px;
    color: var(--pink-swan);
    font-family: 'Lato', Helvetica, Arial, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
  }

  h3 {
    color: white;
    font-family: 'Lato', Helvetica, Arial, serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
  }
  .lineDivider {
    background-color: var(--shark);
    height: 1px;
    width: 100%;
    margin: 0.75em 0 1em 0;
  }
}

/* Image */
.image {
  position: relative;
  margin: 1em 0;
  min-height: 20vmax;
}

.image .cover .img,
.image .cover {
  width: 100%;
  object-fit: cover;

  @include media-breakpoint-up(xxl) {
    height: 65vh;
  }

  @include media-breakpoint-between(lg, xxl) {
    height: 58vh;
  }

  @include media-breakpoint-down(lg) {
    height: 50vh;
  }

  @include media-breakpoint-down(md) {
    height: 35vh;
  }

  @include media-breakpoint-down(sm) {
    height: 25vh;
  }
}
.image .graphs {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  box-sizing: border-box;
}

.image .points {
  position: absolute;
  bottom: 1em;
  left: 1em;
  display: flex;
  gap: 5px;
}

.image .play {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    padding: 0;
    background: rgba(21, 21, 21, 0.9);
    border: 1px solid #fff;
    font-size: 40px;
    color: white;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 0 10px black;
    transition: background-color 0.25s;
    &:hover {
      background: rgb(255, 121, 0);
      border: 1px solid #fff;
      transition: background-color 0.25s;
    }
    i {
      position: relative;
      left: 5px;

      @include media-breakpoint-down(lg) {
        position: relative;
        left: 4px;
        font-size: 30px;
        top: -3px;
      }
    }

    @include media-breakpoint-down(lg) {
      width: 70px;
      height: 70px;
    }
  }
}

.meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include media-breakpoint-down(lg) {
    justify-content: space-between;
    margin-top: 20px;
  }
}

.meta .metaSection {
  border-left: 1px solid #333;
  padding: 15px;
  flex: 1;
  line-height: 1.4;
  strong {
    font-weight: 700;
  }
  @include media-breakpoint-down(lg) {
    border: none;
    padding: 0;
  }
  &:nth-child(1) {
    @include media-breakpoint-down(lg) {
      text-align: left;
    }
  }
  &:nth-child(2) {
    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }
  &:nth-child(3) {
    @include media-breakpoint-down(lg) {
      text-align: right;
    }
  }
  &:nth-child(4) {
    @include media-breakpoint-down(lg) {
      flex-basis: 100%;
      text-align: center;
      line-height: 21px;
      margin-top: 20px;
    }
  }
}

.meta .metaSectionMine {
  border-left: 1px solid #333;
  padding: 1em;
  line-height: 1.4;
  strong {
    font-weight: 700;
  }
  @include media-breakpoint-down(lg) {
    border: none;
    padding: 0;
  }
  &:nth-child(1) {
    @include media-breakpoint-down(lg) {
      text-align: left;
    }
  }
  &:nth-child(2) {
    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }
  &:nth-child(3) {
    @include media-breakpoint-down(lg) {
      text-align: right;
    }
  }
  &:nth-child(4) {
    flex: 2;
    @include media-breakpoint-down(lg) {
      flex-basis: 100%;
      text-align: center;
      line-height: 21px;
      margin-top: 20px;
    }
  }
}

.meta .metaSection .trainingLevel {
  display: flex;
  align-items: center;
  .trainingLevelPoints {
    margin-left: 5px;
  }
}

.meta .metaSection .material {
  color: rgba(88, 88, 88, 1);
  font-family: 'Lato', Helvetica, Arial, serif;
  font-size: 16px;
}

.tags {
  display: flex;
  align-items: center;
  margin-top: 30px;

  .tagsText {
    margin-right: 11px;
    text-align: left;
    font: normal normal normal 15px/18px Lato;
    letter-spacing: 0px;
    color: #b9b9b9;
    opacity: 1;
  }
}

/* Content */
.content {
  background: #333;
  padding: 1em;
  margin-top: 1em;
  font-size: 16px;
  line-height: 21px;
  color: #b8b8b8;
  font-style: italic;
  position: relative;
  overflow: hidden;
  &:after {
    content: ' ';
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    transform: rotate(45deg) translateX(20px);
  }
}

.content p {
  margin: 0;
}

.trainingClassDetails {
  section {
    margin-top: 50px;

    h2 {
      @include media-breakpoint-down(xl) {
        text-align: center;
      }
    }
  }
}

.commentsAndTrainings {
  display: grid;
  grid-template-columns: 2fr minmax(0, 1fr);

  @include media-breakpoint-down(xl) {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
  }

  .comments,
  .shares {
    margin-right: 30px;

    @include media-breakpoint-down(xl) {
      margin-right: 0;
      margin-top: 50px;
    }
  }

  .asideContent {
    display: flex;
    flex-direction: column;

    .trainings,
    .relatedTrainingClasses {
      font-size: 15px;
      font-family: 'Lato', Helvetica, Arial, serif;

      @include media-breakpoint-down(xl) {
        flex-basis: 100%;
        margin-top: 50px;
      }

      li {
        margin-bottom: 20px;
      }
    }
  }
}

.comments .form {
  align-items: flex-start;
  background-color: var(--woodsmoke);
  border-radius: 5px;
  display: flex;
  min-height: 148px;
  overflow: hidden;
  margin-bottom: 20px;
}

.underlineOnHover {
  &:hover {
    text-decoration: underline;
  }
}
