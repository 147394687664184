.container {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: 100%;
  height: 100%;

  .gestureDetector {
    flex-grow: 1;
    width: 100%;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
  
    font-size: 22px;
    color: white;
  }

  .bottomControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    background-color: rgba(36, 38, 39, 0.8);

    width: 100%;
    padding: 15px;

    .duration {
      font-family: 'Lato';
      font-size: 11px;
    }

    .progression {
      display: flex;
      align-items: center;
      gap: 10px;

      flex-grow: 1;

      .progressionRange {
        flex-grow: 1;

        -webkit-appearance: none;
        appearance: none;

        height: 13px;
        background: #424242;
        border-radius: 0;
        background-image: linear-gradient(#FE7D11, #FE7D11);
        background-size: 0% 100%;
        background-repeat: no-repeat;

        cursor: pointer;
      }
      
      .progressionRange::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;

        width: 1px;
        border-radius: 0;
        background: #FE7D11;
      }
    }
  }

  .buffering {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}



@media screen and (-webkit-min-device-pixel-ratio:0) {
}