@import '../../../../../src/css/variables';
@import '../../../../../src/css/breakpoints';

.header {
  background: transparent linear-gradient(180deg, #2f2f2f 0%, #151515 100%) 0% 0% no-repeat
    padding-box;
  display: flex;
  padding: 40px;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .userInfo {
    width: 60%;
    margin-right: 25px;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .levelAffiliation {
    width: 40%;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .userInfo {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    h1 {
      font-size: 38px;
      font-weight: bold;
      font-family: 'Montserrat', Arial, Helvetica, sans-serif;
      margin-bottom: 20px;
      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .info {
      @include media-breakpoint-down(md) {
        margin-top: 20px;
      }
      .location {
        margin: 20px 0;
        background-image: url('../../../../img/icon-location.svg');
        background-repeat: no-repeat;
        padding-left: 20px;
        height: 1.2em;
        vertical-align: middle;
        line-height: 1.2;
      }
    }
  }

  .affiliation{
   display: inline-block;

    &:hover:after{
      content: '';
      background-image: url('@/img/chevron-left-white.png');
      transition: all ease-in .2s;
    }

    &:after {
      margin: -1.4px 0.5em;
      background-image: url('@/img/chevron-left.png');
      background-size: 5px 10px;
      display: inline-block;
      width: 5px; 
      height: 10px;
      content:"";
    }

  }

  .levelAffiliation {
    display: flex;
    justify-content: center;
    flex-direction: column;

    div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: 20px;
      border-left: 1px solid #343434;
      @include media-breakpoint-down(md) {
        margin: 0 auto;
        margin-top: 30px;
        background-color: #2f2f2f;
        padding: 20px;
        width: 100%;
      }
    }

    strong {
      letter-spacing: 1.36px;
      line-height: 15px;
      white-space: nowrap;
      color: var(--pink-swan);
      font-family: 'Lato', Helvetica, Arial, serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    h2 {
      font-family: 'Montserrat', Arial, Helvetica, sans-serif;
      font-size: 14px;
      font-weight: 700;
    }
  }

  aside {
    margin: 0 20px;
  }
}
