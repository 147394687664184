.inputTag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  background-color: #444444;
  width: 100%;
  border-radius: 4px;
  padding-left: 5px;

  .content {
    display: flex;
    align-items: center;
  }

  .row {
    padding: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
  }
}

.inputTag .close {
  height: 12px;
  transform: rotate(180deg);
  width: 12px;
  border: none;
  padding: 0;
  margin-right: 15px;
  margin-left: 10px;
  background: url('../../../../img/icon-close.svg');
  background-size: cover;
}

.draggable {
  background-color: rgba(255, 255, 255, 0.076);
  border: 1px solid rgba(255, 255, 255, 0.494);
  position: relative;
}

.rowDraggable {
  padding: 22px;
}