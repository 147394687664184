.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 4px 4px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: var(--shark);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  font-family: 'Lato', Helvetica, Arial, serif;
  box-shadow:0 4px 4px rgb(0 0 0 / 80%);
  margin-top: 8px;
}

.dropdown-menu.show {
  display: block;
  border:0;
}

.dropdown-menu-end {
  inset: inherit;
  right: 0;
  transform: none;
}

.dropdown-item {
  font-size: 14px;
  display: flex;
  align-items:center;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: var(--star-dust);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  transition: all 0.35s;

  &:focus,
  &:hover {
    background-color: #2f2f2f;
    color:white;
  }
}

.dropdown-divider {
  height: 0;
  margin: 0.25rem 0;
  overflow: hidden;
  border-top: 1px solid var(--star-dust);
  opacity: 0.25;
}
