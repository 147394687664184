@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.modal {
  width: 75%;
  max-width: 450px;
  color: #c7c7c7;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.modal .title {
  align-self: flex-end;
  line-height: 20px;
  min-height: 26px;
  text-align: center;
  width: 100%;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  color: #fff;
  font-family: 'Montserrat';
  margin-bottom: 1em;
}

.description {
  color: var(--pink-swan);
  margin-bottom: 10px;
  font: normal normal normal 13px/16px Lato;
  white-space: normal;
}

.points {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;

  .profile {
    display: flex;
    justify-content: space-evenly;
  }
  .life {
    display: flex;
  }

  .life {
    .ability {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 10px;

      label {
        margin-top: 15px;
        font-size: 14px;
      }
    }
  }
}

.buttons {
  margin-top: 3em;
  text-align: center;
  display: flex;
  justify-content: center;
}
