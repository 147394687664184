.graph {
  flex: 1 1 0;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: .3s;

  &.loaded {
    opacity: 1;
  }

  .zone {
    height: 20%;
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: fill;
  }
}