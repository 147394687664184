@import '../../../../css/variables';
@import '../../../../css/breakpoints';

a:hover {
  color: #b9b9b9;
}

.class.bgWhite {
  background-color: white;
  text-shadow: 0 0px 8px rgba(0, 0, 0, 1);
}

.completed {
  position: absolute;
  width: 100%;
  height: 100%;

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  img {
    border: 0;
    z-index: 5;
    left: 98px;
    top: 70px;
    width: 60px;
    position: absolute;

    @include media-breakpoint-up(xxl) {
      left: 133px;
      top: 95px;
    }
  }
}

.class {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: relative;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);

  .cover {
    display: flex;
    min-height: 100px;
    background-size: 110%;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: background-size 0.3s;

    @include media-breakpoint-up(xxl) {
      min-height: 140px;
    }

    @include media-breakpoint-down(lg) {
      background-size: cover;
    }

    &:hover {
      background-size: 120%;

      @include media-breakpoint-down(lg) {
        background-size: cover;
      }
    }

    @include media-breakpoint-down(sm) {
      min-height: 100px;
    }

    .info {
      display: flex;
      flex-direction: row;
      gap: 3px;

      div {
        font-size: 14px;
      }
    }

    .graphs {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 33%;
      height: 52%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .breakDay {
    @include media-breakpoint-up(xxl) {
      height: 245px;
    }
  }

  .extra {
    min-height: 100px;
    width: 100%;
    background-color: rgb(32, 32, 32);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;

    @include media-breakpoint-up(xxl) {
      padding: 16px;
      min-height: 110px;
    }



    .levelnr {
      display: flex;
      flex-direction: row;
      align-items: center;

      .on,
      .off {
        border-radius: 50%;
        width: 5px;
        height: 5px;
        margin: 2px;
      }

      .on:first-child,
      .off:first-child {
        margin-left: 5px;
      }

      .on {
        background-color: white;
      }

      .off {
        background-color: rgb(78, 78, 78);
      }
    }

    .year {
      font-size: 13px;
      font-family: 'Lato', Helvetica, Arial, serif;
      line-height: 17px;
      color: #b9b9b9;
      text-transform: lowercase;
    }

    .classSubtitle {
      display: flex;
      width: 100%;
      margin: 15px 0;
      font-size: 15px;
      font-weight: 700;
      font-family: 'Montserrat', Helvetica, Arial, serif;
      color: white;

      .classSubtitleContent {
        flex: 1 1 0%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .classtitle {
      width: 80%;
      font-size: 15px;
      font-weight: 700;
      font-family: 'Montserrat', Helvetica, Arial, serif;
    }

    .classTitleContent {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .videoprogress {
      @include media-breakpoint-down(l) {
        font-size: 14px;
      }
    }
  }
}

.listcontainer {
  padding: 20px 0 0 20px;
}

.trainer {
  h1 {
    font-size: 13px;
    font-family: 'Lato', Helvetica, Arial, serif;
    line-height: 17px;
    color: #b9b9b9;
  }
}

.trianglecategory {
  min-width: 20px;
  min-height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.titleBreak {
  margin-top: 10px;
  color: white;
}

.descriptionBreak {
  color: #b9b9b9;
  width: 100%;
  margin: 10px 0;
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
  font-family: 'lato', Helvetica, Arial, serif;

  p {
    padding-top: 10px;
  }
}

.breakDay {
  &:hover {
    background-color: lighten(#202020, +10%);
  }
}

.editOptions {
  display: inline-flex;
  position: absolute;
  height: 60%;
  width: 100%;
  align-items: center;
  z-index: 2;

  @include media-breakpoint-down(xxl) {
    height: 52%;
  }

  div {
    display: block;
    align-items: center;
    align-content: center;
    margin: auto;

    img {
      height: 55px;
      transition: height 0.2s ease-out;

      @include media-breakpoint-down(xxl) {
        height: 43px;
      }

      &:hover {
        height: 50px;
        transition: height 0.2s ease-out;

        @include media-breakpoint-down(xxl) {
          height: 38px;
        }

        @include media-breakpoint-down(lg) {
          height: 43px;
        }
      }
    }
  }
}

.videoProgressHeader {
  height: 98px;

  h1 {
    font-size: 15px;
    line-height: 21px;
    font-weight: 700;
    font-family: 'Montserrat', Helvetica, Arial, serif;
  }

  .videoProgressDescription {
    font-weight: 500;
    color: #b9b9b9;
    font-size: 12px;
    line-height: 16px;

    @include media-breakpoint-up(l) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.progress {
  min-height: 153px !important;

  @include media-breakpoint-down(xxl) {
    min-height: 102px !important;
  }
}

.playprogress {
  width: 50px;
  position: absolute;
  top: 60%;
  left: 45%;

  @include media-breakpoint-down(xxl) {
    width: 40px;
    top: 65%;
    left: 43%;
  }
}

.noOfficialCoverContainer {
  overflow: hidden;

  .noOfficalCover {
    display: flex;
    min-height: 100px;
    flex-direction: column;
    transition: transform 0.3s;

    @include media-breakpoint-up(xxl) {
      min-height: 140px;
    }

    &:hover {
      transform: scale(1.05);

      @include media-breakpoint-down(lg) {
        transform: none;
      }
    }
  }
}