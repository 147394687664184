@import '@/css/variables';
@import '@/css/breakpoints';

$padding: 45px;

.container {
  padding: 0;

  display: flex;
  flex-direction: column;

  max-width: 74vh;
  height: 78vh;

  overflow: hidden;
  padding-top: $padding;

  background-color: #262626;

  @include media-breakpoint-up(xxl) {
    max-width: 620px;
    height: 604px;
  }
}

.container .contentContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;

  overflow-y: scroll;

  margin: 15px 0;
  margin-right: 10px;

  &::-webkit-scrollbar {
    width: 7px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #747474;
  }

  &::-webkit-scrollbar {
    display: -webkit-box;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; 
  }
}

.contentContainer::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.contentContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

.contentContainer::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.contentContainer .header {
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 0 $padding;

  h4 {
    font: normal normal bold 18px/22px Montserrat;
  }

  p {
    font: normal normal normal 15px/21px Lato;
    color: #9C9C9C;
    line-height: 1.4;
  }
}

.contentContainer .content {
  flex-grow: 1;

  padding: 0 30px 0 $padding;
  
  font: normal normal normal 15px/21px Lato;
  color: grey;
}

.container .footer {
  display: flex;
  justify-content: space-between;
  background-color: #2F2F2F;

  padding: 1em 45px;
}