// @import '../../../../css/variables';
// @import '../../../../css/breakpoints';

// $showInfoHeight: 50px;

// .modalContainer {
//   @include media-breakpoint-down(lg) {
//     height: 80vh;
//   }

//   @include media-breakpoint-down(md) {
//     height: 100vh;
//   }
// }

// .container {
//   position: relative;

//   display: flex;
//   flex-direction: row;

//   padding: 0;
//   height: 60vh;
//   width: min-content;

//   .exerciseInstruction {
//     position: absolute;
//     bottom: 0;

//     z-index: 2;

//     display: none;

//     font-family: Lato;
//   }

//   .video {
//     width: auto;
//     height: 100%;

//     object-fit: cover;
//     pointer-events: none;

//     background-color: #262626;

//     @include media-breakpoint-down(lg) {
//       width: 100%;
//       height: 50%;
//       order: 1;

//       transition: height 0.3s;
//     }
//   }

//   .videoContainer {
//     height: 100%;
//     width: 60vh;
//     position: relative;
//   }

//   .loading {
//     position: absolute;
//     top: 50%;
//     left: 50%;

//     transform: translate(-50%, -50%);
//   }

//   .content {
//     position: relative;

//     min-width: 340px;
//     width: 20%;

//     padding: 50px 26px 25px 40px;

//     @include media-breakpoint-down(lg) {
//       width: 100%;
//       height: 50%;

//       align-self: flex-end;

//       padding: 20px 10px $showInfoHeight 27px;

//       order: 2;
//       overflow: hidden;

//       transition:
//         padding-top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
//         opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
//         height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//     }

//       .data {
//         display: flex;
//         flex-direction: column;
//         gap: 10px;

//         .name {
//           font: normal normal bold 24px/29px Montserrat;
//           letter-spacing: 0px;
//           color: #ffffff;
//           text-transform: uppercase;
//         }

//         .text {
//           text-align: left;
//           font: normal normal normal 15px/21px Lato;
//           letter-spacing: 0px;
//           color: #b9b9b9;

//           @include media-breakpoint-down(lg) {
//             color: white;
//           }
//         }

//         .stepsTitle {
//           font: normal normal bold 18px/22px Montserrat;
//           letter-spacing: 0px;
//           color: #ffffff;
//         }

//         .steps {
//           display: flex;
//           flex-direction: column;
//           gap: 30px;
//           margin-top: 10px;

//           .step {
//             display: flex;
//             align-items: start;
//             gap: 12px;

//             .stepIndex {
//               position: relative;
//               display: flex;
//               justify-content: center;
//               align-items: center;

//               width: 20px;
//               height: 20px;

//               background-color: #656565;
//               color: #2f2f2f;

//               border-radius: 50%;

//               font-family: 'Lato';
//               font-size: 13px;
//               font-weight: bold;
//             }

//             .text {
//               overflow: hidden;
//               flex: 1;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// // small screen
// @include media-breakpoint-down(lg) {
//   .container {
//     width: 80vh;
//     height: 100%;
//     margin: 0px;
//     max-width: 100%;
//     flex-direction: column;
//     overflow-y: auto;
//   }
//   .left {
//     left: 10px;
//   }
//   .right {
//     right: 10px;
//   }
//   .videoContainer {
//     height: 100vw !important;
//     width: 100vw !important;
//   }

//   .exerciseInstruction {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 10px;

//     background-color: transparent;
//     color: white;

//     width: 100%;
//     padding: 15px;

//     border: none;
//     outline: none;

//     height: $showInfoHeight;

//     .text {
//       color: #d1d1d1;
//     }
//   }
// }

// // mobile
// @include media-breakpoint-down(sm) {
//   .container {
//     height: 100%;
//     margin: 0px;
//     max-width: 100%;
//     flex-direction: column;
//     overflow-y: auto;
//   }

//   .videoContainer {
//     height: 100vw !important;
//     width: 100vw !important;
//   }

//   .exerciseInstruction {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 10px;

//     background-color: transparent;
//     color: white;

//     width: 100%;
//     padding: 15px;

//     border: none;
//     outline: none;

//     height: $showInfoHeight;

//     .text {
//       color: #d1d1d1;
//     }
//   }
// }

.modalContainer {
  padding: 0;
  height: 60vh;
  display: flex;
  flex-direction: row;

  // asumiendo por ahora que siempre va a ser cuadrado
  .videoContainer {
    height: 60vh;
    width: 60vh;

    .video {
      width: auto;
      height: 60vh;
    }

    .loading {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    width: 450px;
    min-width: 250px;
    padding: 50px 26px 25px 40px;
    overflow: auto;

    p,
    span {
      color: #b9b9b9;
      font: normal normal normal 15px/21px Lato;
      letter-spacing: 0;
      text-align: left;
    }

    .name {
      font: normal normal bold 24px/29px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      text-transform: uppercase;
    }

    .description {
      text-align: left;
      font: normal normal normal 15px/21px Lato;
      letter-spacing: 0px;
      color: #b9b9b9;
    }

    .stepsTitle {
      margin-top: 20px;
      font: normal normal bold 18px/22px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
    }

    .steps {
      display: flex;
      flex-direction: column;

      .step {
        gap: 12px;
        margin-top: 12px;
        display: flex;
        align-items: start;

        .stepIndex {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          width: 20px;
          height: 20px;

          background-color: #656565;
          color: #2f2f2f;

          border-radius: 50%;

          font-family: 'Lato';
          font-size: 13px;
          font-weight: bold;
        }

        .text {
          overflow: hidden;
          flex: 1;
        }
      }
    }
  }

  @media screen and (max-width: 1275px) {
    height: 80vh;

    .videoContainer {
      height: 100%;
      width: 40vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;

      video {
        height: auto !important;
        width: 100% !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    max-width: none;
    height: 100vh;
    margin: 0;
    flex-direction: column;

    .videoContainer {
      height: 100vw !important;
      width: 100vw !important;
      video,
      .loading {
        height: 100vw !important;
        width: auto !important;
      }
    }

    .emptySpace {
      height: calc((100vh - 100vw) / 2);
    }

    .content {
      height: calc((100vh - 100vw) / 2);
      width: 100%;
    }

    .content:has(.description) {
      height: calc(100vh - 100vw);
    }
  }
}

.hidden {
  display: none;
}

.scrollable {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 20px;

  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;

  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #747474;
  }

  &::-webkit-scrollbar {
    display: -webkit-box;
  }
}
