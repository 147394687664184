.divider {
  border-top: 1px solid #333;
  height: 1px;
  margin-top: 7px;
  width: 100%;
}

[id='dividerMenuMainItems'] {
  border-top: 1px solid #333;
  height: 1px;
  margin-top: 7px;
  margin-bottom: 7px;
  width: 100%;
}
