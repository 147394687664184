.modal {
  background: #2f2f2f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000081;
  width: 500px;

  .title {
    text-align: center;
    font: normal normal bold 24px/29px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 23px;
    width: 416px;
  }

  .description {
    text-align: center;
    font: normal normal normal 16px/21px Lato;
    letter-spacing: 0px;
    color: #b2b2b1;
    width: 415px;
  }

  footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}
