.image {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: .3s;

  &.loaded {
    opacity: 1;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}