@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.modal {
  max-width: 800px;
  background: transparent linear-gradient(360deg, #151515 0%, #262626 100%) 0% 0% no-repeat padding-box;
  @include media-breakpoint-down(sm) {
    padding: 53px 35px;
  }
}
.appdownload {
  display: flex;
}

.appdownload .content {
  flex: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .header {
    width: 100%;
    .logo {
      height: 39px;
      background-image: url('../../../../img/bestcycling-life-logo.svg');
      font-family: 'Montserrat', Arial, Helvetica, sans-serif;
      @include media-breakpoint-down(sm) {
        margin-top: 10px;
        height: 34px;
      }
      @include media-breakpoint-down(md) {
        background-position: center;
      }
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .body {
    font-family: 'Lato';

    p {
      margin: 1em 0;
      font-size: 16px;
      line-height: 1.25;
      color: white;
      strong {
        font-weight: bold;
      }
    }
  }
  .footer {
    display: flex;
    width: 100%;
    margin-top: 1.5em;
    .mobile_app_link {
      display: block;
      flex: 1;
      height: 56px;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .google_play {
      margin-right: 12px;
      background-image: url('../../../../img/download-apps/download-android@2x.png');
      @include media-breakpoint-down(sm) {
        background-image: url('../../../../img/download-apps/download-android.png');
      }
      @include media-breakpoint-down(md) {
        background-position: right;
      }
    }
    .app_store {
      margin-right: 12px;
      background-image: url('../../../../img/download-apps/download-apple@2x.png');
      @include media-breakpoint-down(sm) {
        background-image: url('../../../../img/download-apps/download-apple.png');
      }
      @include media-breakpoint-down(md) {
        background-position: left;
      }
    }
  }
}

.appdownload .image {
  flex: 1;
  width: 274px;
  height: 550px;
  background-image: url('../../../../img/download-apps/iphone-popup-3@2x.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: -37px;
  margin-bottom: -37px;
  @include media-breakpoint-down(md) {
    display: none;
  }
}
