@import '../../../../css/breakpoints';
@import '../../../../css/variables';

.moreInfoContainer {
  margin-top: 13px;
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;
  grid-template-areas: 'tss . . . .' 'times-container divider cadences divider2 zones';
  grid-auto-flow: row;

  padding: 3px;
  row-gap: 54px;
  column-gap: 30px;

  .tssContainer {
    grid-area: tss;

    .tss {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 13px;
      text-align: left;
      font: normal normal normal 15px/19px Lato;
      letter-spacing: 0px;
      color: #b9b9b9;
    }
  }
  .timesContainer {
    grid-area: times-container;
  }
  .cadences {
    grid-area: cadences;
  }
  .zones {
    grid-area: zones;
  }

  .divider,
  .divider2 {
    width: 1px;
    height: 230px;
    background: #343434 0% 0% no-repeat padding-box;
    opacity: 0.5;
  }
  .dividerHor {
    height: 1px;
    background: #343434 0% 0% no-repeat padding-box;
    opacity: 0.5;
  }

  .divider {
    grid-area: divider;
  }

  .divider2 {
    grid-area: divider2;
  }
  .header {
    text-align: center;
    font: normal normal bold 15px/19px Lato;
    letter-spacing: 1.28px;
    color: #b9b9b9;
    margin-bottom: 32px;
  }

  .rowStats {
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding-bottom: 17px;
    font: normal normal normal 15px/19px Lato;
    letter-spacing: 0px;
    color: #b9b9b9;

    .data {
      display: grid;
      grid-template-columns: 4fr 1fr 1fr;
      gap: 10px;

      .name {
        flex: 2;
        white-space: nowrap;
      }

      .value {
        text-align: right;
        white-space: nowrap;
      }
    }
  }

  @include media-breakpoint-down(xxl) {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    grid-template-areas: 'tss tss tss' 'times-container divider cadences' 'zones zones zones';
    column-gap: 30px;

    .divider2 {
      display: none;
    }
  }

  @include media-breakpoint-down(xl) {
    display: grid;
    grid-template-areas:
      'tss'
      'times-container'
      'cadences'
      'zones';
    grid-template-columns: 1fr;
    column-gap: 0px;

    .divider,
    .divider2 {
      display: none;
    }
  }
}
