@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.collection {
  border: none;
  background: transparent;
  padding: 0;
}

.collection:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.collection .border {
  padding: 0;
  border: 2px solid var(--celeste);
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;

  img {
    margin-top: 3px;
    margin-left: 1px;
    height: 20px;
    width: 20px;
  }
}

.collection .icon {
  height: 20px;
  width: 20px;
}
