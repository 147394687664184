@import '../../../../../../css/variables';
@import '../../../../../../css/breakpoints';

.container {
  .white_overlay {
    display: block;
    background: linear-gradient(180deg, rgba(19, 19, 19, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    min-height: 70px;
    min-width: 100%;
  }

  .cover {
    overflow: hidden;
  }

  &:hover .cover .image {
    transform: scale(1.05);
  }

  @include media-breakpoint-down(lg) {
    &:hover .cover .image {
      transform: none;
    }
  }

  .cover {
    display: flex;
    min-height: 110px;

    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    position: relative;

    .image {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 0%;
      transition: transform 0.3s;

      @include media-breakpoint-down(sm) {
        min-height: 130px;
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      z-index: 1;

      .points {
        display: flex;
        gap: 3px;
        & > div {
          font-size: 14px;
        }
      }

      .materials {
        display: flex;
        align-items: center;
      }
    }

    .graphs {
      position: absolute;
      top: 0;
      right: 0;
      width: 32%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 0.75em;
    }
  }
}