.deviceList {
  background: #252526 0% 0% no-repeat padding-box;
  max-height: 400px;
  overflow: auto;
}

ul::-webkit-scrollbar {
  display: block;
}

.bikeConnected {
  background: #444444 0% 0% no-repeat padding-box;
  border-radius: 8px;
}

.divider {
  border: 1px solid var(--line-divider);
  border: 1px solid #343434;
  width: auto;
  height: 0px;
  display: flex;
}

.deviceButtonPrefs {
  background: #2f2f2f 0% 0% no-repeat padding-box;
}

.deviceButton {
  background: #252526 0% 0% no-repeat padding-box;
}

.deviceButton,
.deviceButtonPrefs {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  border: none;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .deviceName,
  .deviceNameConnected {
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
  }

  .deviceName {
    color: #b9b9b9;
  }
  .deviceNameConnected {
    color: #ff7900;
  }

  .status {
    text-align: left;
    font: normal normal normal 13px/16px Lato;
    letter-spacing: 0px;
    color: #747474;
  }

  .containerData {
    display: flex;
    align-items: center;
    gap: 10px;
    .deviceData,
    .deviceDataConnected {
      text-align: left;
      font: normal normal normal 14px/17px Lato;
      letter-spacing: 0px;
      color: #b9b9b9;
    }

    .deviceData {
      color: #b9b9b9;
    }
    .deviceDataConnected {
      color: #ff7900;
    }

    img {
      width: 16px;
      height: 26px;
    }
  }
}
