@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.trainingCategoryCard {
  display: block;
  width: 257px;
  height: 170px;

  @include media-breakpoint-up(xxl) {
    width: 328px;
    height: 205px;
  }
  &.modal {
    width: 100%;
    margin: 0;
    padding-right: 0;
  }
}

.modal.bestcycling,
.modal.bestbalance,
.modal.bestmind,
.modal.bestwalking,
.modal.bestrunning,
.modal.besttraining {
  position: relative;
  width: 100%;
  float: left;
  box-sizing: content-box;
  display: flex;
  height: 100%;
  background-color: black;
  cursor: pointer;
  background-size: 200%;
  background-repeat: no-repeat;
  background-position: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
  transition: background-size 0.3s;

  @include media-breakpoint-up(xxl) {
    background-size: 200%;
  }

  @include media-breakpoint-down(xxl) {
    background-size: 240%;
  }

  @include media-breakpoint-down(lg) {
    background-size: cover;
  }

  &:hover {
    transition: background-size 0.3s;

    @include media-breakpoint-up(xxl) {
      background-size: 210%;
    }

    @include media-breakpoint-down(xxl) {
      background-size: 250%;
    }

    @include media-breakpoint-down(lg) {
      background-size: cover;
    }
  }
}

.bestcycling,
.besttraining,
.bestrunning,
.bestbalance,
.bestwalking,
.bestmind {
  position: relative;
  width: 100%;
  float: left;
  box-sizing: content-box;
  display: flex;
  height: 100%;
  background-color: black;
  cursor: pointer;
  background-size: 170%;
  background-repeat: no-repeat;
  background-position: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
  transition: background-size 0.3s;

  @include media-breakpoint-down(xxl) {
    background-size: 180%;
  }

  @include media-breakpoint-down(lg) {
    background-size: cover;
  }

  &:hover {
    transition: background-size 0.3s;
    background-size: 180%;

    @include media-breakpoint-down(xxl) {
      background-size: 190%;
    }

    @include media-breakpoint-down(lg) {
      background-size: cover;
    }
  }

  img {
    z-index: 1;
    max-height: 30px;
    @include media-breakpoint-up(xxl) {
      max-height: 35px;
    }
  }
  &.modal img {
    max-height: 35px;
  }
}

.bestcycling {
  background-image: url(/src/img/bestcycling.jpg);
}
.besttraining {
  background-image: url(/src/img/besttraining.jpg);
}
.bestrunning {
  background-image: url(/src/img/bestrunning.jpg);
}
.bestbalance {
  background-image: url(/src/img/bestbalance.jpg);
}
.bestwalking {
  background-image: url(/src/img/bestwalking.jpg);
}
.bestmind {
  background-image: url(/src/img/bestmind.jpg);
}

.overlay {
  background-color: #121212;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 257px;
  min-height: 170px;

  @include media-breakpoint-up(xxl) {
    width: 328px;
    height: 205px;
  }
  &.modal {
    width: 100%;
    height: 100%;
  }
}

.trianglecategory {
  min-width: 20px;
  min-height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
