.card {
  background: var(---ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  width: auto;
  display: inline-block;
  max-width: 100%;
}

.cardRow {
  height: 21px;
  display: flex;
  align-items: center;
  width:auto;
}

.iconShared {
  width: 22px;
  height: 15px;
  margin-top: 2px;
  margin-left: 13px;
  margin-right: 7px;
  object-fit: scale-down;
  opacity: 1;
}

.trainer {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px Lato;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--card-background-2f2f2f);
  text-align: left;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0px;
  color: #2f2f2f;
  opacity: 1;
  margin-right: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
