@import '../../../../css/variables';
@import '../../../../css/breakpoints';

button.hidde {
  display: none;
}

button.show {
  display: inline-block;
}

button.download {
  border: none;
  background: transparent;
  padding: 0;
}

button.download.active .border {
  border: 2px solid var(--orange);
}

.border {
  padding: 0;
  border: 2px solid var(--celeste);
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    height: 33px;
    width: 33px;
  }
}

.icon {
  height: 20px;
  width: 20px;

  @include media-breakpoint-down(sm) {
    height: 15px;
    width: 15px;
  }
}
