.toggle_switch {
  input {
    display: none;
  }

  .toggle_switch_toggle {
    position: relative;
    display: block;
    width: 2.3rem;
    height: 1rem;
    background: rgb(95, 100, 111);
    cursor: pointer;
    border-radius: 15px;
    transition: all 250ms ease-in-out;

    &:after {
      content: '';
      position: absolute;
      width: 1.3rem;
      height: 1.3rem;
      background: #eee;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
      border-radius: 50%;
      box-shadow: 0 0 10px 1px #262626;
      transition: all 250ms ease-in-out;
    }
  }

  input[type='checkbox']:checked + .toggle_switch_toggle {
    background: #ff7900;
  }
  input[type='checkbox']:checked + .toggle_switch_toggle:after {
    background: #fff;
    box-shadow: 0 0 10px 1px #2626266c;
    left: calc(1rem);
  }
  input:disabled + .toggle_switch_toggle {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
  }
}
