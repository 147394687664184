#app {
  background-color: var(--licorice);
  display: flex;
  flex-direction: row;
}

#app #navigation {
  position: fixed;
  height: 100vh;
  z-index: 20;
}

#app #main {
  display: flex;
  flex: 1;
  flex-basis: auto;
  flex-direction: column;

  max-width: calc(100% - #{$navigation-width});
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}

#app #navigation + #main {
  margin-left: $navigation-width;
  @include media-breakpoint-down(lg) {
    margin-left: 0;
  }
}

#app #main.desktop {
  max-width: 100%;
}

#app #main.desktop #footer {
  display: none;
}

#app #main.hide-navigation {
  max-width: 100%;
}

#content {
  background-color: var(--licorice);
}

#app #main #content {
  position: relative;
  flex: 1 1 0;
}

#app #main #content > div {
  display: flex;
  width: 100%;
  height: 100%;
}

#header {
  height: $topbar-height;
  min-height: $topbar-height;
}
