.notFound {
  line-height: 1.7;
  text-align: center;
  height: 100%;
  background: linear-gradient(0deg, #2d2d2d, transparent);
  margin: -14px -34px;

  h2 {
    font-size: 26px;
    margin: 2em 0 1em 0;
    font-weight: bold;
    color: #ff7900;
  }

  p {
    font-size: 16px;
    margin: 1em 0;
  }

  button {
    background: none;
    padding: 0;
    color: white;
    font-size: 14px;
    text-align: center;
    border: 1px solid #ff7900;
    padding: 12px 20px;
    border-radius: 31px;
    margin: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}