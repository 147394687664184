.container {
  position: relative;
  font-size: 13px;
}

.dropdownMenuButton {
  all: unset;
  display: flex;
  padding: 9px 22px 9px 37px;
  box-sizing: border-box;
  align-content: center;
  width: 100%;
  margin-top: 7px;
}

.dropdownMenuButton:hover,
.dropdownMenuItem:hover {
  background-color: #1f1e1e;
}

.text {
  margin: 3px 0px 0px 21px;
}

.dropdownMenu {
  position: fixed;
  background-color: #151515;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  top: -63px;
  z-index: 20;
  font-size: 13px;
}

.icon {
  width: 20px;
  height: 20px;
}

.dropdownMenuItem {
  margin-top: 7px;
  button,
  a {
    all: unset;
    cursor: pointer;
    padding: 9px 32px 9px 22px;
    display: flex;
    align-items: center;
    span {
      margin-top: -1px;
    }
  }
}

.dropdownMenuItem:last-child {
  margin-bottom: 7px;
}

.dropdownMenuArrow {
  position: absolute;
  width: 15px;
  color: #151515;
  z-index: 20;
  transform: rotate(180deg);
}

.chevron {
  display: none;
  position: absolute;
  right: 24px;
  top: 22px;
}

@media (max-width: 767px) {
  .chevron {
    display: inherit;
  }

  .dropdownMenuItem {
    padding-left: 15px;
  }
}
