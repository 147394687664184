@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.grid {
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: 1fr 1fr;

  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
  }
}

.container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  h3 {
    letter-spacing: 1.36px;
    line-height: 15px;
    min-width: 214px;

    color: var(--pink-swan);
    font-family: 'Lato', Helvetica, Arial, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 45px;
    margin-bottom: 15px;
    text-align: center;
  }
}
