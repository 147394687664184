@use 'sass:math';

$width: 50px;
$border: 5px;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $width + $border + $border;

  &.icon{
    min-height:25px;
  }
}

.spinner {
  height: math.div($width, 2);
  width: $width;
  border: $border solid #ff7900;
  border-bottom: 0;
  border-radius: math.div($width, 2) math.div($width, 2) 0 0;
  transform-origin: bottom center;
  animation: spinner 0.5s infinite linear;
  margin-top: math.div(-$width, 2);

  &.icon{
    height: 12px;
    margin-top: -18px;
    margin-right:10px;
    width: 24px;
    border: 2px solid #ff7900;
    border-bottom: 0;
    border-radius: math.div($width, 2) math.div($width, 2) 0 0;
    transform-origin: bottom center;
    animation: spinner 0.5s infinite linear;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
