:focus,
:focus-visible,
.focus-visible {
  outline-color: white;
  outline-width: 3px;
  outline-style: auto;
}
[data-js-focus-visible] .focus-visible {
  outline-color: white;
  outline-width: 3px;
  outline-style: auto;
}
[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  outline: none;
}
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  opacity: 1;
}
