@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.filter {
  label {
    color: #b9b9b9;
    display: flex;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 12px;
  }
  .select {
    text-transform: capitalize;
  }
}

.schedulerFilter {
  .schedulerFilterLabel {
    display: block;
    margin-bottom: 13px;
    text-align: left;
    font: normal normal normal 14px/18px Lato;
    letter-spacing: 0px;
    color: #aaaaaa;
    opacity: 1;
  }
  .schedulerFilterLabelDisabled {
    color: #aaa;
    display: block;
    margin-bottom: 13px;
    opacity: 0.31;
    pointer-events: none;
    font: normal normal normal 14px/18px Lato;
    letter-spacing: 0px;
    color: #aaaaaa;
  }

  .schedulerFilterSelect {
    text-align: left;
    font: normal normal bold 15px/19px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    padding-left: 23.6px;
    border: none;
    background-color: #000000;
    border-radius: 24px;
    text-transform: uppercase;
  }

  .schedulerFilterSelect:disabled {
    background-color: #000000;
    border-radius: 24px;
    opacity: 0.31;
    pointer-events: none;
    text-align: left;
    padding-left: 23.6px;
    border: none;
    font: normal normal bold 15px/19px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .schdeulerFilterSelectInstructor {
    background-color: #000000;
    border-radius: 24px;
    text-align: left;
    border: none;
    font: normal normal bold 15px/19px Montserrat;
    padding-left: 23.6px;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
  }

  .schdeulerFilterSelectInstructor:disabled {
    background-color: #000000;
    border-radius: 24px;
    opacity: 0.31;
    pointer-events: none;
    color: #ffffff;
    padding-left: 23.6px;
    border: none;
    text-align: left;
    font: normal normal bold 15px/19px Montserrat;
    text-transform: uppercase;
    letter-spacing: 0px;
  }
}

.wrapcontainer {
  display: flex;
  flex-direction: column;
  flex: 1;

  @include media-breakpoint-down(sm) {
    margin-bottom: 14px;
  }

  .searchMobileFilter {
    height: 0px;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s,
      opacity 0.3s linear;

    input {
      height: 0px;
      width: 100%;
      font-size: 14px;
    }
    input::placeholder {
      color: #b9b9b9;
      font-family: 'Lato', Helvetica, Arial, serif;
    }

    input:focus::placeholder {
      color: transparent;
    }
  }

  .searchMobileFilter.search_visible {
    padding: 10px 20px;
    height: 40px;
    visibility: visible;
    opacity: 1;
    margin-top: 10px;
    margin-bottom: 10px;

    input {
      width: 100%;
      margin-right: 20px;
      height: 40px;
      background-color: #262626;
      border: none;
      border-radius: 20px;
      padding: 0 20px;
      color: #b9b9b9;
    }
  }
}

.filterscontainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 0 14px 0;

  @include media-breakpoint-down(md) {
    margin: 1.5%;
  }

  @include media-breakpoint-down(sm) {
    margin: 0;
  }

  label {
    flex-direction: column;
  }

  select {
    min-width: 100px;
    max-width: 100px;
    padding: 10px;
    background: url(/src/img/dropdown-arrow@2x.png) no-repeat;
    background-size: 17px;
    background-position: calc(100% - 8px);
    box-shadow: none;
    padding-right: 20px;
    text-overflow: ellipsis;
  }

  .searchInput {
    margin-right: 20px;
    height: 40px;
    background-color: #262626;
    border: none;
    border-radius: 20px;
    padding: 0 20px;
    background-image: url(/src/img/icon-explore.png);
    background-position: calc(100% - 15px);
    background-repeat: no-repeat;
    color: #b9b9b9;

    @include media-breakpoint-down(lg) {
      margin-right: 0px;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  input::placeholder {
    color: #b9b9b9;
    font-family: 'Lato', Helvetica, Arial, serif;
  }

  input:focus::placeholder {
    color: transparent;
  }

  @include media-breakpoint-down(xxxl) {
    &.mobile_visible {
      flex-wrap: wrap;

      .group_left {
        display: flex;
        flex-basis: 100%;
        order: 2;
        .filter {
          margin-top: 1.5em;
          @include media-breakpoint-down(sm) {
            padding: 0 1.5em;
          }
        }
      }
    }
  }
}

.group_left,
.group_right {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.group_right {
  justify-content: flex-end;

  @include media-breakpoint-down(md) {
    width: 48%;
  }

  @include media-breakpoint-down(sm) {
    width: 10%;
  }

  .order {
    margin-right: 0;

    @include media-breakpoint-down(xxl) {
      margin-right: 0.5em;
    }

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .order select {
    margin-right: 0;
  }

  div {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

.group_right:last-child {
  margin-right: 0;
}

.group_left {
  @include media-breakpoint-down(xxxl) {
    display: none;
  }
}

.button_filters {
  display: none;
  width: 150px;
  height: 40px;
  background-color: #262626;
  transition: background-color 0.3s ease;
  border: none;
  padding: 0 20px;
  color: #b9b9b9;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 2px;
  font-family: 'Lato', Helvetica, Arial, serif;

  &:hover {
    background-color: lighten(#262626, +10%);
  }

  svg {
    height: 18px;
    margin-right: 10px;
  }

  @include media-breakpoint-down(xxxl) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include media-breakpoint-down(md) {
    width: 48%;
  }

  @include media-breakpoint-down(sm) {
    width: 90%;
    margin-right: 20px;
  }
}

.button_search {
  display: none;
  height: 40px;
  background-color: #262626;
  border: none;
  padding: 0 20px;
  border-radius: 2px;

  @include media-breakpoint-down(sm) {
    display: flex;
    width: 48px;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 25px;
    height: 25px;
  }
}

.mobile_visible .group_left {
  @include media-breakpoint-down(sm) {
    display: flex;
    overflow: scroll;
    overflow-x: hidden;
    height: auto;
    flex-wrap: nowrap;
    padding-bottom: 2em;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    flex-direction: column;
    align-items: flex-start;
    z-index: 3;

    .dynamicFilter {
      width: 100%;
    }

    div.filter {
      width: 100%;

      .dynamicSelect {
        width: 100%;
        max-width: 100%;
      }
    }

    .filter label {
      display: flex;
      color: #b9b9b9;
      font-size: 12px;
      line-height: 15px;
      text-indent: 2px;
      text-transform: capitalize;
    }
    .filter select {
      max-width: 100%;
      width: 100%;
      padding: 1em;
    }

    div.schedulerFilter {
      width: 100%;
    }

    .schedulerFilter label {
      display: flex;
      color: #b9b9b9;
      font-size: 12px;
      line-height: 15px;
      text-indent: 2px;
      text-transform: capitalize;
    }
    .schedulerFilter select {
      max-width: 100%;
      width: 100%;
      padding: 1em;
      color: #fff;
      font-weight: bold;
    }
  }
}

.mobile_topbar {
  display: none;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 1em;
  z-index: 100;

  .mobile_topbar_back {
    display: flex;
    align-items: center;
    button {
      width: 15px;
      height: 15px;
      border: 0;
      background: url('../../../../img/chevron-right@2x.png');
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 2em;
    }
    .title {
      letter-spacing: 2px;
    }
  }
}

.mobile_visible .mobile_topbar {
  @include media-breakpoint-down(sm) {
    display: flex;
  }
}

.dynamicFilter {
  margin-right: 20px;
  .dynamicSelect {
    position: relative;
    top: 0;
    max-width: 150px;
    padding: 10px;
    background: url(/src/img/dropdown-arrow@2x.png) no-repeat;
    background-size: 17px;
    background-position: calc(100% - 8px);
    box-shadow: none;
    color: #b9b9b9;
    border: 1px solid #343434;
    border-radius: 3px;
    margin-right: 40px;
    cursor: pointer;
    -webkit-appearance: none;
    font-family: 'Lato', Helvetica, Arial, serif;
    width: 100%;
    font-size: 14px;
    text-shadow: 1px 1px rgb(0 0 0 / 50%);
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-rendering: auto;
    align-items: center;
    white-space: pre;
    -webkit-rtl-ordering: logical;

    .dropdown {
      overflow: hidden;
      position: absolute;
      top: 50px;
      left: 0;
      width: 250px;
      z-index: 2;
      border-radius: 8px;
      border: 1px solid #262626;
      box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    }
  }
}
