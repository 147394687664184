.avatar {
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  &.primary { border: 2px solid var(--orange); }

  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}