@use "sass:math";

.userProgressBar {
  width: 100%;
  background-color: #333;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  .progress {
    height: 100%;
    width: 0;
  }

  &.progress0 {
    background-color: transparent;
  }

  @for $i from 0 through 10 {
    $value: ($i * 10);
    &.progress#{$value} .progress {
      width: #{percentage(math.div($value, 100))};
    }
  }
}
