@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.title {
  letter-spacing: 0px;
  line-height: 1;
  min-height: 26px;
  color: white;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin: 1.95em 0 1em 0;
}

.link {
  display: inline-block;
}