@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.modal{
  max-width: 562px;
  max-height: 90%;

  background-color: #2f2f2f;
}

.body .warning {
  padding: 7px 20px;

  p.text {
    padding: 8px 0;
  }
}

.iconTitle{
  display: flex;
  flex-direction: column;
  align-items: center;

  > *:first-child{ // the icon
      margin-bottom: 12px;
  }
}

.container {
  padding: 7px;
  background-color: #262626;
  margin-top: 10px;
  border-radius: 3px;
}

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #747474;
}

.content::-webkit-scrollbar {
  display: -webkit-box;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;

  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;

  height: 200px;

  @include media-breakpoint-up(xxl){
    height: inherit;
    max-height: 400px;
  }

  p.text {
    text-align: left;
    padding: 0;
    line-height: 1.5;
  }

  .rules {
    display: flex;
    flex-direction: column;
    gap: 7px;

    margin-left: 30px;
    list-style-type: decimal;
  }
}