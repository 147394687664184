@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.gymsMenuRoomsHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 49px;
  padding-right: 23.59px;
  cursor: pointer;

  .gymsMenuRoomsTitle {
    font: normal normal 600 13px/16px Montserrat;
    letter-spacing: 0px;
    color: #9c9c9c;
    opacity: 1;
  }
}
.gymsSchedulerNav {
  display: flex;
  align-items: center;
}
.chevronDown {
  width: auto;
  height: 10px;
  margin-top: 5.6px;
  padding: 1px;
}

.chevronUp {
  width: auto;
  height: 10px;
  margin-top: 5.6px;
  padding: 1px;
  transform: rotate(180deg);
}

.gymsMenuRoomsArrow {
  color: #9c9c9c;
  align-items: center;
  background-size: cover;
  border: none;
  margin-left: 10px;
  position: absolute;
  right: 25px;
  top: 12px;
}
.gymsMenuTrainersHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  padding-left: 49px;
  padding-right: 23.59px;
  cursor: pointer;

  .gymsMenuTrainersTitle {
    font: normal normal 600 13px/16px Montserrat;
    letter-spacing: 0px;
    color: #9c9c9c;
    opacity: 1;
  }

  .gymsMenuTrainersArrow {
    color: #9c9c9c;
    align-items: center;
    background-size: cover;
    border: none;
  }
}

.gymsMenuAddTrainers {
  display: flex;
  flex-direction: row;
  width: 231.77px;
  margin-top: 15.42px;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;

  .gymsMenuAddTrainersIcon {
    width: 18px;
    height: 18px;
    background: transparent url(../../../../img/icon-more-thin.svg) 0% 0% no-repeat padding-box;
    border: none;
    opacity: 1;
  }

  .gymsMenuAddTrainerText {
    color: var(--menu-9c9c9c);
    text-align: left;
    font: normal normal bold 13px/16px Montserrat;
    letter-spacing: 0.52px;
    color: #9c9c9c;
    text-transform: uppercase;
    opacity: 1;
  }
}

.navigation {
  align-items: center;
  background-color: black;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 25.5px 0;
  width: $navigation-width;

  @include media-breakpoint-down(lg) {
    position: fixed;
    z-index: 1;
    top: 0;
    left: -$navigation-width;
    // overflow-x: hidden;
    padding-top: 60px;
    transition: left 0.25s ease-out;

    &.menu-opened {
      left: 0;
      overflow-y: scroll;
      overflow-x: visible;
    }
  }

  .hide-navigation & {
    display: none;
  }
}

.navigation .logo {
  align-items: flex-start;
  display: flex;
  height: 30px;
  justify-content: flex-end;
  margin-left: 11.09px;
  min-width: 202px;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.navigation .menu {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  flex: 1;
  width: $navigation-width;
  overflow: scroll;

  @include media-breakpoint-down(lg) {
    margin-top: 0;
    width: 100%;
  }
}

.navigation .menu .menu-main-items {
  width: 100%;

  @include media-breakpoint-up(md) {
    display: none;
  }

  .menu-main-content {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    transition: all 0.35s;

    &.expanded {
      max-height: 100vh;
    }
  }
}

.navigation .menu .menu-item {
  align-items: flex-end;
  display: flex;
  min-width: 267px;
  margin-top: 7px;
  padding: 9px 22px 9px 37px;
  width: 100%;
  position: relative;

  .menu-item-inner {
    align-items: center;
    display: flex;
    min-width: 76px;
    width: 100%;
  }

  &:hover,
  &.menu-item-current {
    background-color: var(--eerie-black);
  }

  .menu-item-icon {
    height: 20px;
    width: 20px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .menu-item-text {
    align-self: flex-end;
    letter-spacing: 0px;
    line-height: 14px;
    margin-bottom: -2.5px;
    margin-left: 21px;
    min-height: 19px;
    min-width: 35px;
    white-space: nowrap;
  }

  &.menu-item-text .selected {
    color: #fff;
  }

  &.menu-item-current .menu-item-text {
    color: #fff;
  }

  &.menu-main {
    .menu-item-text {
      margin-left: 0;
      color: #c3c3c3;
      text-transform: uppercase;
      font: normal normal 600 14px/18px Montserrat;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.menu-item:last-child {
  margin-bottom: 30px;
}
.sidebar-menu > *:last-child {
  margin-bottom: 30px;
}

.navigation {
  .menu-separator {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include media-breakpoint-down(lg) {
      flex: 0;
    }
  }

  .menu-burguer {
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
    }
  }

  .menu-mobile-close {
    display: none;
  }
}

.navigation .menu-mobile-close {
  align-items: flex-end;
  background-color: black;
  display: flex;
  height: 46px;
  justify-content: flex-end;
  right: 10px;
  min-width: 46px;
  position: absolute;
  top: 10px;
  background-image: url(/src/img/icon-close.png);

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.menu-main-items .menu-main__text {
  display: flex;
  justify-content: space-between;
  color: #c3c3c3;
  font: normal normal 600 14px/18px Montserrat;
  text-transform: uppercase;
  background-color: transparent;

  min-width: 267px;
  margin-top: 7px;
  padding: 9px 22px 9px 37px;
  width: 100%;
}

/* Iconos menú */
.navigation {
  .menu-item .home {
    background-image: url(/src/img/icon-home.png);
    background-position: -3%;
  }

  .menu-item:focus .home,
  .menu-item-current .home {
    background-image: url(/src/img/icon-home.png);
    background-position: 103%;
  }

  .menu-item .search {
    background-image: url(/src/img/icon-search-menu.png);
    background-position: 0%;
  }

  .menu-item:focus .search,
  .menu-item-current .search {
    background-image: url(/src/img/icon-search-menu.png);
    background-position: 100%;
  }

  .menu-item .favourite {
    background-image: url(/src/img/favourite-menu.png);
    background-position: 0%;
  }

  .menu-item:focus .favourite,
  .menu-item-current .favourite {
    background-image: url(/src/img/favourite-menu.png);
    background-position: 100%;
  }

  .menu-item .list {
    background-image: url(/src/img/icon-list-menu.png);
    background-position: 0%;
  }

  .menu-item:focus .list,
  .menu-item-current .list {
    background-image: url(/src/img/icon-list-menu.png);
    background-position: 92%;
  }

  .menu-item .menu-item-icon.download {
    background-image: url(/src/img/icon-offline.svg);
    background-size: 100% 100%;
    opacity: 0.8;
  }

  .menu-item .menu-item-icon.downloadApp {
    background-image: url(/src/img/icon-download-app.svg);
    background-size: 100% 100%;
    opacity: 0.8;
  }

  .menu-item:focus .menu-item-icon.download,
  .menu-item-current .menu-item-icon.download {
    opacity: 1;
  }

  .menu-item .preferences {
    background-image: url(/src/img/icon-preferencias.svg);
  }

  .menu-item .nutrition {
    background-image: url(/src/img/icon-nutrition.png);
    background-position: 0%;
  }

  .menu-item:focus .nutrition,
  .menu-item-current .nutrition {
    background-image: url(/src/img/icon-nutrition.png);
    background-position: 98%;
  }

  .menu-item .sensei {
    background-image: url(/src/img/icon-sensei.png);
    background-position: 4%;
  }

  .menu-item:focus .sensei,
  .menu-item-current .sensei {
    background-image: url(/src/img/icon-sensei.png);
    background-position: 97%;
  }

  .menu-item .profile {
    background-image: url(/src/img/icon-profile.png);
    background-position: 4%;
  }

  .menu-item:focus .profile,
  .menu-item-current .profile {
    background-image: url(/src/img/icon-profile.png);
    background-position: 97%;
  }

  .menu-item .constructor {
    background-image: url(/src/img/bestpro/constructor-icon.png);
    background-position: 4%;
  }

  .menu-item .help {
    background: url(/src/img/icon-dropdown-help.svg);
    background-position: 4%;
  }

  .menu-item:focus .download-app,
  .menu-item-current .download-app {
    background-image: url(/src/img/icon-ayuda.svg);
    background-position: 97%;
  }

  .menu-item:focus .help,
  .menu-item-current .help {
    background-image: url(/src/img/icon-ayuda.svg);
    background-position: 97%;
  }

  .menu-item .more {
    background-image: url(/src/img/icon-more-thin.svg);
    background-position: 4%;
  }

  .menu-item:focus .more,
  .menu-item-current .more {
    background-image: url(/src/img/icon-more-thin.svg);
    background-position: 97%;
  }

  .menu-item .myclasses {
    background-image: url(/src/img/bestpro/icon-classes-1.png);
    background-position: 4%;
  }

  .menu-item:focus .myclasses,
  .menu-item-current .myclasses {
    background-image: url(/src/img/bestpro/icon-classes-1.png);
    background-position: 100%;
  }

  .menu-item .express {
    background-image: url(/src/img/bestpro/icon-mixer.png);
    background-position: 4%;
  }

  .menu-item:focus .express,
  .menu-item-current .express {
    background-image: url(/src/img/bestpro/icon-mixer.png);
    background-position: 100%;
  }

  .menu-item .organizer {
    background-image: url(/src/img/bestpro/organizer-icon.png);
    background-position: 4%;
  }

  .menu-item:focus .organizer,
  .menu-item-current .organizer {
    background-image: url(/src/img/bestpro/organizer-icon.png);
    background-position: 97%;
  }

  .menu-item .constructor {
    background-image: url(/src/img/bestpro/constructor-icon.png);
    background-position: 4%;
  }

  .menu-item:focus .constructor,
  .menu-item-current .constructor {
    background-image: url(/src/img/bestpro/constructor-icon.png);
    background-position: 97%;
  }

  .menu-item .mixer {
    background-image: url(/src/img/bestpro/mixer-icon.png);
    background-position: 4%;
  }

  .menu-item:focus .mixer,
  .menu-item-current .mixer {
    background-image: url(/src/img/bestpro/mixer-icon.png);
    background-position: 97%;
  }

  .menu-item .planificador {
    background-image: url(/src/img/scheduler/planificador-icon.png);
    background-position: 0%;
  }

  .menu-item:focus .planificador,
  .menu-item-current .planificador {
    background-image: url(/src/img/scheduler/planificador-icon.png);
    background-position: 100%;
  }
}

.sidebar-menu {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 3px;

  .menu-tools {
    font-size: 12px;
    margin-top: 7px;
    padding: 9px 22px 9px 37px;
    letter-spacing: 1px;
    line-height: 14px;
  }

  .menu-rooms-active {
    max-height: 500px;
    transition: max-height 0.25s linear;
  }

  .menu-rooms-unactive {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.25s linear;
  }
}
/* Estilos de la barra de desplazamiento en WebKit (Chrome, Safari) */
.sidebar-menu::-webkit-scrollbar {
  display: -webkit-box;
  width: 10px !important;
  height: 10px !important;
  border: 0 !important;
  background-color: #1d1d1d !important;
}

.sidebar-menu {
  @include media-breakpoint-down(lg) {
    // height: 390px;
    overflow-y: auto;
  }
}
