@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.secondary-menu {
  align-items: center;
  background-color: var(--licorice);
  display: flex;
  padding: 10px 28px 10px 12px;
  width: calc(100% - $navigation-width);
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  height: $topbar-height;

  @include media-breakpoint-down(lg) {
    background-color: black;
    padding: 10px 15px;
  }
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.secondary-menu .hide-navigation {
  display: none;
}

.group-left,
.group-right {
  display: flex;
  align-items: center;
}

.secondary-menu .icon-chevron-left {
  height: 16px;
  width: 9px;
}
.secondary-menu .icon-chevron-right {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  min-width: 9px;
  opacity: 0.3;
  transform: rotate(180deg);
}

.lato-normal-white-14px {
  color: white;
  font-family: 'Lato', Helvetica, Arial, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

#top_nav_back,
#top_nav_next {
  margin-left: 18px;
  @include media-breakpoint-down(lg) {
  }
}

#top_nav_next {
  margin-right: 18px;
}

.secondary-menu .next-page {
  background-color: var(--eerie-black);
  border-radius: 16px;
  display: flex;
  height: 32px;
  justify-content: flex-end;
  margin-bottom: 1px;
  min-width: 32px;
}
.secondary-menu .previous-page {
  align-items: flex-end;
  background-color: var(--eerie-black);
  border-radius: 16px;
  display: flex;
  height: 32px;
  margin-bottom: 1px;
  min-width: 32px;
}

.secondary-menu .overlap-group {
  height: 29px;
  margin-top: -0.25px;
  position: relative;
  width: 32px;
}

.secondary-menu .menu-mobile {
  display: none;
  @include media-breakpoint-down(lg) {
    display: flex;
    align-items: center;
  }
}

.secondary-menu .logo-bestlife {
  height: 28px;
  width: 41px;
}

.secondary-menu .icon-burguer-menu {
  align-items: flex-start;
  background-color: black;
  display: flex;
  height: 46px;
  width: 46px;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.secondary-menu .subscribeButton {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
