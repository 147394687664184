.dropdown {
  position: absolute;
  top: 25px;

  min-width: 20rem;
  width: fit-content;

  border-radius: 0.25rem;
  box-shadow: 0 4px 10px rgb(0 0 0 / 60%);

  margin-top: 8px;
  padding: 8px;

  font-size: 14px;
  line-height: 19px;
  font-weight: 400;

  background-color: var(--shark);
  z-index: 10;
}

.right {
  left: 0;
}

.left {
  right: -20px;
}
