@import '../../../../css/breakpoints';
@import '../../../../css/variables';

.container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.containerGenreName {
  display: flex;
  gap: 3px;
}

.genreName {
  text-transform: capitalize;
}

.containerTagsList {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.iconMusic {
  margin-top: 1px;
}