@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.preferences {
  button {
    background-color: #0e0e0e;
    border: none;
    text-align: center;
  }

  p {
    line-height: 1.5;
  }

  h2 {
    margin: 2em 0 1em;
    font-weight: bold;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    margin-top: 40px;
    font-size: 20px;
    text-align: center;
    line-height: 26px;

    @include media-breakpoint-down(md) {
      text-align: left;
      font-size: 18px;
    }
  }

  h3 {
    letter-spacing: 1.36px;
    line-height: 15px;
    min-width: 214px;

    color: var(--pink-swan);
    font-family: 'Lato', Helvetica, Arial, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 45px;
    margin-bottom: 15px;
    text-align: center;
    &:first-child {
      margin-top: 45px;
    }
    &:first-of-type {
      margin-bottom: 15px;
      margin-top: 45px;
    }
  }

  li p {
    font-size: 14px;
    line-height: 20px;
  }

  a {
    color: #ff7900;
  }

  .containerUser {
    background-color: #343434;
    padding: 2em;
    position: relative;
    @include media-breakpoint-down(sm) {
      padding: 1.5em;
    }
  }

  .userObjetiveLevel {
    color: white;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .userMonth,
  .userPoints {
    font-size: 15px;
    line-height: 20px;
  }
  .userLevel {
    text-align: start;
    margin-top: 0;
  }

  .progressbar {
    width: 100%;
    height: 30px;
    background-color: black;
    margin: 20px 0 10px 0;
    @include media-breakpoint-down(sm) {
      margin: 20px 0 0px 0;
    }
  }

  .statusbar {
    background-color: #ff7900;
    height: 30px;
    position: relative;
  }

  .friends {
    font-size: 1.8em;
    color: white;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .points {
    font-size: 2.5em;
    color: white;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .month {
    color: white;
    font-size: 15px;
    line-height: 21px;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-transform: initial;
  }

  .txt {
    font-size: 14px;
    color: #b9b9b9;
    line-height: 19px;
  }

  .description {
    line-height: 1.4;
    margin-bottom: 40px;
    font-size: 14px;
  }

  .title {
    font-size: 18px;
    line-height: 25px;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }
}

.description {
  text-align: left;
  letter-spacing: 0px;
}

.input_container {
  align-items: center;
}
.bluetooth_container {
  align-items: flex-start;
}
.input_container,
.bluetooth_container {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;

  p {
    padding-right: 15px;
    font-size: 14px;
  }

  label select {
    margin-right: 20px;
  }
  input {
    font-size: 14px;
    width: 135px;
  }
}

.input_container_colors {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  p {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: white;
  }

  .label_colors {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    color: rgb(102, 102, 102);
    font-weight: 500;
  }

  .color_input {
    margin: 5px;
  }
}

.separator {
  height: 1;
  border: none;
  border-bottom: 1px solid #333;
  margin: 1em 0;
}
.configuration {
  label {
    width: 135px;
  }
}

.info {
  color: #d1d1d1;
  font-size: 13px;
  line-height: 19px;
}
.containerLink {
  background-color: #343434;
  padding: 1.5em;
  text-align: center;
  border-radius: 6px;
  margin-top: 20px;

  .linkAffiliate {
    width: 100%;
    color: white;
    text-align: center;
    background: none;
    border: none;
    font-size: 1em;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

.preferences {
  textarea {
    all: unset;
    color: white;
    font:
      14px 'Lato',
      Arial,
      Helvetica,
      sans-serif;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 12px;
    padding: 14px;
    margin: 14px 0;
    width: calc(100% - 24px); // 100% - padding
  }
  a[href^='mailto'] {
    font:
      14px 'Lato',
      Arial,
      Helvetica,
      sans-serif;
  }

  p + .greyed {
    font-size: 13px;
    color: rgb(102, 102, 102);
    display: block;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
  }
}

span.description {
  font-size: 13px;
  color: #666666;
  display: block;
  margin-top: 5px;
  margin-bottom: 0 !important;
}

.graph_colors {
  margin-top: 25px;
  max-width: 100%;
  display: grid;
  gap: 10px;
  row-gap: 45px;
  grid-template-columns: repeat(4, 1fr);

  li {
    max-width: 100%;
    div {
      max-width: 100%;
      cursor: pointer;
    }
    p {
      margin-top: 15px;
    }
  }
  .graphType_selected div {
    border: 2px solid #ff7900;
    margin-top: -2px;
    margin-left: -2px;
    box-sizing: content-box;
  }
  .graphType_not_selected div {
    border: 2px solid #0e0e0e;
    margin-top: -2px;
    margin-left: -2px;
    box-sizing: content-box;
  }

  .BestcyclingPot,
  .BestcyclingPotScaled,
  .BestcyclingFC,
  .BestcyclingFCScaled,
  .Bestcycling2Pot,
  .Bestcycling2PotScaled,
  .Bestcycling2FC,
  .Bestcycling2FCScaled,
  .GarminTMPot,
  .GarminTMPotScaled,
  .GarminTMFC,
  .GarminTMFCScaled,
  .PolarPot,
  .PolarPotScaled,
  .PolarFC,
  .PolarFCScaled,
  .Bestcyling4zonasPot,
  .Bestcyling4zonasPotScaled,
  .Bestcyling4zonasFC,
  .Bestcyling4zonasFCScaled,
  .Bestcyling4zonasmejoradoPot,
  .Bestcyling4zonasmejoradoPotScaled,
  .Bestcyling4zonasmejoradoFC,
  .Bestcyling4zonasmejoradoFCScaled,
  .AllBlackPot,
  .AllBlackPotScaled,
  .AllBlackFC,
  .AllBlackFCScaled,
  .TemperaturaPot,
  .TemperaturaPotScaled,
  .TemperaturaFC,
  .TemperaturaFCScaled {
    min-height: 100px;
    background-position: bottom;
  }

  .BestcyclingPot,
  .BestcyclingPotScaled {
    background-image: url('../../../../img/graphThemes/Bestcycling-Potencia.png');
  }

  .BestcyclingFC,
  .BestcyclingFCScaled {
    background-image: url('../../../../img/graphThemes/Bestcycling-FC.png');
  }

  .Bestcycling2Pot,
  .Bestcycling2PotScaled {
    background-image: url('../../../../img/graphThemes/Bestcycling2-Potencia.png');
  }
  .Bestcycling2FC,
  .Bestcycling2FCScaled {
    background-image: url('../../../../img/graphThemes/Bestcycling2-FC.png');
  }
  .GarminTMPot,
  .GarminTMPotScaled {
    background-image: url('../../../../img/graphThemes/Garmin-Potencia.png');
  }
  .GarminTMFC,
  .GarminTMFCScaled {
    background-image: url('../../../../img/graphThemes/Garmin-FC.png');
  }
  .PolarPot,
  .PolarPotScaled {
    background-image: url('../../../../img/graphThemes/Polar-Potencia.png');
  }
  .PolarFC,
  .PolarFCScaled {
    background-image: url('../../../../img/graphThemes/Polar-FC.png');
  }
  .Bestcyling4zonasPot,
  .Bestcyling4zonasPotScaled {
    background-image: url('../../../../img/graphThemes/Bestcycling4zonas-Potencia.png');
  }
  .Bestcyling4zonasFC,
  .Bestcyling4zonasFCScaled {
    background-image: url('../../../../img/graphThemes/Bestcycling4zonas-FC.png');
  }
  .Bestcyling4zonasmejoradoPot,
  .Bestcyling4zonasmejoradoPotScaled {
    background-image: url('../../../../img/graphThemes/Bestcycling4mejorado-Potencia.png');
  }
  .Bestcyling4zonasmejoradoFC,
  .Bestcyling4zonasmejoradoFCScaled {
    background-image: url('../../../../img/graphThemes/Bestcycling4mejorado-FC.png');
  }
  .AllBlackPot,
  .AllBlackPotScaled {
    background-image: url('../../../../img/graphThemes/AllBlack-Potencia.png');
  }
  .AllBlackFC,
  .AllBlackFCScaled {
    background-image: url('../../../../img/graphThemes/AllBlack-FC.png');
  }
  .TemperaturaPot,
  .TemperaturaPotScaled {
    background-image: url('../../../../img/graphThemes/Temperatura-Potencia.png');
  }
  .TemperaturaFC,
  .TemperaturaFCScaled {
    background-image: url('../../../../img/graphThemes/Temperatura-FC.png');
  }

  .BestcyclingPot,
  .BestcyclingFC,
  .Bestcycling2Pot,
  .Bestcycling2FC,
  .GarminTMPot,
  .GarminTMFC,
  .PolarPot,
  .PolarFC,
  .Bestcyling4zonasPot,
  .Bestcyling4zonasFC,
  .Bestcyling4zonasmejoradoPot,
  .Bestcyling4zonasmejoradoFC,
  .AllBlackPot,
  .AllBlackFC,
  .TemperaturaPot,
  .TemperaturaFC {
    background-size: 100% 100%;
  }
  .BestcyclingPotScaled,
  .Bestcycling2PotScaled,
  .GarminTMPotScaled,
  .PolarPotScaled,
  .Bestcyling4zonasPotScaled,
  .Bestcyling4zonasmejoradoPotScaled,
  .AllBlackPotScaled,
  .TemperaturaPotScaled {
    background-size: 100% 110%;
  }
  .BestcyclingFCScaled,
  .Bestcycling2FCScaled,
  .GarminTMFCScaled,
  .PolarFCScaled,
  .Bestcyling4zonasFCScaled,
  .Bestcyling4zonasmejoradoFCScaled,
  .AllBlackFCScaled,
  .TemperaturaFCScaled {
    background-size: 100% 115%;
  }

  // Mobile
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.settingInput {
  margin-top: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    font-size: 14px;
  }
  p {
    max-width: 70%;
  }
  input {
    min-width: 100px;
    padding-left: 14px;
    line-height: 40px;
    width: 120px;
    height: 40px;
    color: #b9b9b9;
    background-color: #272727;
    border: none;
    border-radius: 3px;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
  }
}

.prefTip {
  font-size: 13px;
  color: #666;
  display: block;
  margin-top: 5px;
  margin-right: 15px;
}
