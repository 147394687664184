@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.modal {
  height: 100%;
  width: 100%;
  background-color: #151515;
  margin: 0px;
  overflow-y: auto;
  padding: 45px 90px;
  @include media-breakpoint-down(md) {
    padding: 30px 30px;
    max-width: 100%;
  }
}
.modal button {
  z-index: 3;
  position: fixed !important;
}
.modal .buttons {
  margin-top: 2em;
  margin-bottom: 2em;

  button {
    position: relative !important;
    margin: 0 auto;
  }
}

.blocks {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;

  .blockNumber {
    display: block;

    font-size: 15px;
    font-family: 'Lato';
    text-align: center;

    color: #b9b9b9;
  }
}

.titleContainer {
  background-color: black;
  width: 100%;
  height: 55px;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 2;
}

.modal .title {
  align-self: flex-end;
  line-height: 20px;
  min-height: 26px;
  text-align: center;
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #fff;
  font-family: 'Lato';
  line-height: 50px;
}
.trainingCard {
  max-width: 510px;
  min-width: 400px;
  align-items: center;
  margin: 50px auto 0px auto;
  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }
}
.modal .titleGraph {
  text-align: center;
  margin-top: 40px;
  letter-spacing: 1.36px;
  line-height: 15px;
  min-width: 214px;
  white-space: nowrap;
  color: var(--pink-swan);
  font-family: 'Lato', Helvetica, Arial, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.details {
  max-width: 500px;
  margin: auto;
  margin-top: 50px;
  text-align: center;
}

.modal .displaysInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .display {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 250px;
    float: left;
    height: 110px;
    background-color: black;
    margin-top: 20px;
    margin-right: 25px;
    border-radius: 10px;
    @include media-breakpoint-down(md) {
      min-width: 140px;
      font-size: 0.8em;
    }

    .value {
      color: white;
      font-family: 'Montserrat', Arial, Helvetica, sans-serif;
      font-size: 32px;
      padding-left: 20px;
      padding-top: 20px;
      font-weight: 600;
      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }

    .units {
      display: inline;
      font-size: 20px;
      padding-left: 10px;
      font-weight: bold;
      color: white;
    }

    .label {
      font-size: 15px;
      line-height: 18px;
      padding-left: 20px;
      padding-top: 25px;
      max-width: 80%;
      @include media-breakpoint-down(md) {
        font-size: 13px;
        line-height: 15px;
      }
    }
  }
  .large {
    width: 30%;
    min-width: 300px;
    @include media-breakpoint-down(md) {
      min-width: 140px;
      font-size: 0.8em;
    }
  }
}
.graphContainer {
  width: 100%;
  @include media-breakpoint-down(md) {
  }
}

.displayRounds {
  display: flex;
  justify-content: space-between;

  > div {
    flex-grow: 1;
    flex-basis: 0;
    float: left;
  }
  flex-wrap: wrap;
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
  .displayRound {
    font-size: 13px;
    margin-top: 20px;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 30px;

    .roundTitle {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      font-size: 11px;
    }
    .columnsRound {
      display: flex;
      gap: 10px;
      margin-right: 10px;
    }

    .roundExercises {
      .lineExercise {
        display: flex;
        justify-content: space-between;

        background-color: #1d1d1d;
        width: 100%;
        height: 100%;

        line-height: 35px;
        border-bottom: 1px solid #252525;
        overflow: hidden;

        &.bigger {
          padding: 6px 0;
        }

        .description {
          display: flex;
          gap: 10px;
          float: left;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          padding: 0 10px;
          .method{
            color: white;
            text-transform: capitalize;
          }
        }
        .rounds {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 15px;
        }

        .previous,
        .today,
        .totals {
          width: 20px;
          font-size: 20px;
          font-weight: 700;
          color: white;
          font-family: 'Montserrat';
          justify-content: center;
        }

        .previous,
        .today {
          margin: 0 12px;
        }
        .totals {
          margin: 0 20px;
        }

        .cyclingMethod {
          font-weight: 500;
          color: white;
        }
      }
    }
  }
}
