@import '../../../../css/breakpoints';
@import '../../../../css/variables';

.genreName {
  min-width: 76px;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: 'Lato';

  @include media-breakpoint-down(md) {
    width: 100px;
  }
}

.genreValue {
  font-style: italic;
}

.container {
  display: flex;
  padding: 30px 0px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.blockWithBorder {
  border-left: 1px solid #333;
  padding: 15px;
  flex: 1;
  line-height: 1.4;

  @include media-breakpoint-down(md) {
    border-left: none;
    padding: 0px;
    margin-bottom: 15px;
  }
}

.centeredBlock {
  justify-content: center;
}

.flexWithGap {
  display: flex;
  gap: 20px;
  flex-direction: column;
  min-height: 45px;

  @include media-breakpoint-down(md) {
    min-height: 0px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  display: flex;
  align-items: center;

  color: var(--pink-swan);
  letter-spacing: 1.36px;
  line-height: 15px;
  font-family: "Lato", Helvetica, Arial, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;

  min-height: 45px;

  @include media-breakpoint-down(md) {
    width: 100%;
    justify-content: center;
  }
}

.flexBetween {
  display: flex;
  justify-content: space-between;
}