.training {
  &.hasTrainingData {
    cursor: pointer;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 5px;
  }
  .header .category {
    margin-left: 1em;
    height: 18px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &.bestcycling .header .category {
    background-image: url('../../../../img/logo-color/logo_bestcycling@3x.png');
  }
  &.bestwalking .header .category {
    background-image: url('../../../../img/logo-color/logo_bestwalking@3x.png');
  }
  &.bestbalance .header .category {
    background-image: url('../../../../img/logo-color/logo_bestbalance@3x.png');
  }
  &.bestrunning .header .category {
    background-image: url('../../../../img/logo-color/logo_bestrunning@3x.png');
  }
  &.besttraining .header .category {
    background-image: url('../../../../img/logo-color/logo_besttraining@3x.png');
  }
  &.bestmind .header .category {
    background-image: url('../../../../img/logo-color/logo_bestmind@3x.png');
  }

  .date {
    font-size: 13px;
  }

  .trianglecategory {
    min-width: 20px;
    min-height: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);

    &.bestbalance {
      background-color: #69ae00;
    }
    &.bestcycling {
      background-color: #ff9900;
    }
    &.bestmind {
      background-color: #1d8cb9;
    }
    &.bestrunning {
      background-color: #fcd900;
    }
    &.besttraining {
      background-color: #f13b46;
    }
    &.bestwalking {
      background-color: #8d88c5;
    }
  }

  .body {
    background: #333;
    display: flex;
    gap: 10px;
    padding: 0.75em 1em;
    line-height: 1.5;
    align-items: center;
    position: relative;
    margin-bottom: 15px;
    .content {
      flex: 1;
      overflow: hidden;

      .title {
        color: white;
        font-size: 1.15em;
        font-family: 'Montserrat', Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        margin-bottom: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
      }
      .trainer {
        font-size: 0.95em;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
      }
      .location {
        font-size: 0.95em;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
      }
    }
    .points {
      font-size: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
