.containerInput {
  min-height: 0px;
  max-height: 30vh;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 5px 0px;
  border-radius: 4px;
}

.containerTags {
  .Tag {
    padding: 4px 5px;
    border-radius: 4px;
    margin: 5px;

    &:hover {
      background-color: rgba(100, 100, 100, 0.3);
      cursor: pointer;
    }
  }
}

.invisibleTags {
  background: transparent 0% 0% no-repeat padding-box;
  background-color: #454344;
  border: 1px solid #2f2f2f;
  border-radius: 20px;
  padding: 0px 6px;
  text-align: center;
  font: normal normal normal 12px/14px Lato;
  height: 18px;
  display: flex;
  align-items: center;
  pointer-events: all;

  &.rectangle{
    height: 28px;
    padding: 7px;
    background-color: black;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    border-radius: 0;
    font-weight: 600;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    color: white;

  }
}

.containerTagsList {
  width: 100%;
  overflow: hidden;
  display: inline-flex;
  align-items: center;

  .Tag {
    display: flex;
    pointer-events: none;

    button {
      width: auto;
      padding: 1px 8px;
      margin-right: 5px;
      border-radius: 20px;
      display: inline-block;
    }
  }
}

.tagsAdded {
  display: inline-flex;
  width: auto;
  flex-flow: wrap;

  .tagList button {
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;

    &:hover {
      background-color: #2f2f2f;
    }

    &:focus {
      border: none;
    }
  }
}