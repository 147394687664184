@import '../../../../src/css/variables';
@import '../../../../src/css/breakpoints';

.points {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .profile {
    display: flex;
    justify-content: space-evenly;
    @include media-breakpoint-down(lg) {
      border-top: 1px solid #343434;
      width: 100%;
    }
  }
  .life {
    display: flex;
    @include media-breakpoint-down(lg) {
      justify-content: center;
      border-top: 1px solid #343434;
      border-bottom: 1px solid #343434;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .life {
    .ability {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 10px;

      label {
        margin-top: 15px;
        font-size: 14px;
      }
    }
  }
}

.stats {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(11em, 1fr));

  .stat {
    background: #0e0e0e;
    padding: 20px;
    border-radius: 10px;

    main {
      color: white;
      font-family: 'Montserrat', Arial, Helvetica, sans-serif;
      font-size: 32px;
      font-weight: bold;
      margin: 10px 0;
      white-space: nowrap;

      .units {
        font-size: 0.5em;
      }
    }

    footer {
      font-size: 14px;
      color: #b9b9b9;
      line-height: 16px;
    }
  }
}

.profileSubtitle {
  letter-spacing: 1.36px;
  line-height: 15px;
  min-width: 214px;
  white-space: nowrap;
  color: var(--pink-swan);
  font-family: 'Lato', Helvetica, Arial, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 45px;
  margin-bottom: 15px;
  text-align: center;
  margin-top: 8%;
  margin-bottom: 20px;
}
