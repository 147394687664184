.rounded_button {
  background: transparent 0% 0% no-repeat padding-box;
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  font: normal normal normal 14px/18px Lato;
  letter-spacing: 0.5px;
  padding: 8px 20px;
  text-align: center;
  text-transform: uppercase;

  &.normal {
    font-size: 14px;
  }
  &.small {
    font-size: 12px;
    height: fit-content;
  }

  &.bold {
    font-weight: 600;
    letter-spacing: 0.56px;
    text-transform: uppercase;
  }

  &.primary {
    $bgcolor: #ff7900;
    $textcolor: white;
    border: 1px solid $bgcolor;
    color: $textcolor;
    &:hover {
      background-color: $bgcolor;
      border-color: $textcolor;
    }
  }

  &:disabled {
    background-color: #2f2f2f;
    border-color: #2f2f2f;
    color: #666;
    transition: all 0.25s ease-in-out;
    cursor: not-allowed;
    &:hover {
      background-color: #2f2f2f;
      border-color: #2f2f2f;
    }
  }

  &.secondary {
    $bgcolor: #2f2f2f;
    background-color: $bgcolor;
    color: #b9b9b9;
    &:hover {
      background-color: lighten($bgcolor, +10%);
      cursor: pointer;
    }
    icon {
      color: #ff7900;
    }
  }
  &.ml10 {
    margin-left: 10px;
  }
  &.w100 {
    width: 100%;
    height: 50px;
  }
  &.mt10 {
    margin-top: 10px;
  }
  &.mt30 {
    margin-top: 30px;
  }
}
