.bar {
  width: auto;
  height: 20px;
  border-radius: 10px;
  position: relative;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 30px;
  height: 30px;
  margin: auto;
  background-color: #fff;
  border-radius: 50%;
}
