.share {
  padding: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid #222;
  display: flex;
  background-color: #1d1d1d;

  .body {
    flex: 1;
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 14px;

    .user_name {
      font-weight: bold;
      line-height: 1.25;
    }
  }
}
