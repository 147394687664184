.container {
  display: flex;
  align-items: flex-start;
  gap: 10px;

  .title {
    flex: 1;
    overflow: hidden;
  }

  .offlineIndicator {
    width: 20px;
    margin-top: 0px;
  }
}
