@import '../../../../css/variables';

.collectionBanner {
  width: 100%;
  height: 200px;

  margin-bottom: 10px;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 100%;
    padding: 35px;
  }

  .red{
    background: linear-gradient($red, transparent);
  }
  .orange{
    background: linear-gradient($orange, transparent);
  }
  .yellow{
    background: linear-gradient($yellow, transparent);
  }
  .green{
    background: linear-gradient($green, transparent);
  }
  .blue{
    background: linear-gradient($blue, transparent);
  }
  .blackblue{
    background: linear-gradient($blackblue, transparent);
  }
  .violet{
    background: linear-gradient($violet, transparent);
  }
  .purple{
    background: linear-gradient($purple, transparent);
  }
  .pink{
    background: linear-gradient($pink, transparent);
  }
  .salmon{
    background: linear-gradient($salmon, transparent);
  }

  .collectionName {
    color: white;

    font-family: 'Montserrat', Helvetica, Arial, serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }

  .collectionOptions {
    position: relative;

    button {
      background: none;
      border: none;
    }
  }
}