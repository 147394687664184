@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.title {
  letter-spacing: 0px;
  line-height: 32px;
  color: white;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  // font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin: 0.5em 0;

  @include media-breakpoint-down(md){
    font-size: 1.5em;
  }
}
