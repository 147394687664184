@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.subtitle {
  line-height: 1;
  color: white;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin: 2em 0 1.5em 0;
}

.subtitle:first-of-type{
    margin: 1em 0 1em 0;
}