.header {
  background: black;
  text-align: center;
  padding: 1em;
  
img{
  height: 30px;
  min-width: 202px;
}
}
