.container {
  display: flex;
  flex-direction: column;
  gap: 60px;

  .blocks {
    display: flex;
    flex-direction: column;

    margin-top: 10px;

    h2 {
      margin-bottom: 0;
    }

    .visualTrainings {
      margin-top: 20px;
    }
  }

  .exercises {
    h2 {
      text-align: center;
    }
  }
}