.trainingLevel {
  display: flex;

  .trainingLevelPoint {
    width: 7px;
    height: 7px;

    border-radius: 7px;
    margin-left: 5px;
    margin-top: 3.92px;

    transform: rotate(-90deg);
  }

  &.level10 {
    .trainingLevelPoint:nth-child(2),
    .trainingLevelPoint:nth-child(3) {
      background-color: var(--masala);
    }
  }

  &.level20 {
    .trainingLevelPoint:nth-child(3) {
      background-color: var(--masala);
    }
  }
}

.trainingLevel.white .trainingLevelPoint {
  background-color: white;
}

.trainingLevel.grey .trainingLevelPoint {
  background-color: var(--pink-swan);
}

.trainingLevel.small .trainingLevelPoint {
  width: 5px;
  height: 5px;
  margin-left: 3.3px;
  margin-top: 4.92px;
}