@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.fav {
  border: none;
  background: transparent;
}

.border {
  padding: 0;
  border: 2px solid var(--celeste);
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  height: 40px;
  width: 40px;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    height: 33px;
    width: 33px;
  }
}
.border.active {
  background: transparent;
}
.icon {
  height: 20px;
  width: 20px;

  @include media-breakpoint-down(sm) {
    height: 15px;
    width: 15px;
  }
}

.modalWait {
  text-align: center;
  h5 {
    margin-bottom: 2em;
  }
}

.modalContent {
  margin-bottom: 1em;
  p {
    margin-bottom: 0.5em;
  }
}

.modal .content {
  padding: 2em;
  text-align: center;

  @include media-breakpoint-down(sm) {
    margin: 0.6em;
    padding: 0.5em;
  }

  .header {
    font-family: 'Montserrat', Helvetica, Arial, serif;
    color: white;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    margin-bottom: 10px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 0.5em;
      margin-top: 20px;
    }
  }
  .description {
    font-family: 'Lato', Helvetica, Arial, serif;
    color: #fff;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    width: 70%;
    margin: 0 auto;
  }

  .buttons {
    margin-top: 20px;
    text-align: center;
    display: flex;
  }
}
