@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 1000;
  align-items: center;
  justify-content: center;
}

.shadow {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  animation: fadein 0.25s ease-out;
  background-color: rgba(0, 0, 0, 0.6);
}

.modalContainer {
  display: flex;
  align-items: center;
}

.lateralButton {
  position: relative;
  background-color: transparent;

  outline: none;
  border: none;

  img {
    width: 58px;
    height: 58px;

    @include media-breakpoint-down(md) {
      width: 52px;
      height: 52px;
    }
  }
}

.zindex {
  z-index: 100;
}
.bg {
  background-color: #262626;
}
.modal {
  position: relative;
  background: #262626;
  border-radius: 6px;
  box-shadow: 0px 17px 56px black;
  display: flex;
  padding: 45px 45px;
  flex-direction: column;
  overflow: hidden;
  margin: 16px;
  animation: fadein 0.25s ease-in-out;

  @include media-breakpoint-down(md) {
    padding: 35px 30px;
    max-width: 88%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal .close {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  transform: rotate(180deg);
  width: 20px;
  height: 20px;
  border: none;
  padding: 0;
  margin: 0;
  background: url('../../../../img/icon-close.svg');
  background-size: cover;
}

.modal .bgClose {
  position: absolute;
  left: -50px;
  height: 50px;
  background-color: #ff7900;
  width: 50px;
  cursor: pointer;

  @include media-breakpoint-down(lg) {
    left: 0px;
  }
  .close {
    position: relative;
    top: 15px;
    right: -15px;
  }
}

.modal .title {
  align-self: flex-end;
  line-height: 20px;
  min-height: 26px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  color: var(--pink-swan);
  font-family: 'Lato', Helvetica, Arial, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
}

.modal .lineDivider {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  margin-top: 5px;
  margin-bottom: 5px;
}

.switch_container {
  display: flex;
  margin: 2em 0;
  line-height: 1.2;
  align-items: center;
}

.switch_label {
  flex: 1;
  color: rgba(185, 185, 185, 1);
  font-family: 'Lato', Helvetica, Arial, serif;
  text-transform: none;
  font-weight: normal;
  margin-right: 20px;

  cursor: pointer;
  .span0 {
    font-size: 17px;
    color: #fff;
    line-height: 26px;
  }
  .span1 {
    font-size: 15px;
  }
}
.medias_container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 15px;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
  }

  .media {
    flex: calc(1 / 3);

    &:nth-child(1) {
      margin-right: 10px;
    }
    &:nth-child(2) {
      margin-left: 5px;
      margin-right: 5px;
    }
    &:nth-child(3) {
      margin-left: 10px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;

      display: flex;
      &:nth-child(n) {
        margin: 0;
      }
    }
  }
}

.optionHeader {
  background-color: white;
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  line-height: 33px;
  position: sticky;
  position: -webkit-sticky;
  top: 10px;
  z-index: 50;
  color: #262626;
  border-radius: 5px;
  min-height: 56px;
  margin-bottom: 20px;

  @include media-breakpoint-down(md) {
    text-align: center;
    float: none;
  }

  @include media-breakpoint-down(sm) {
  }
}

.optionHeaderTitle {
  float: left;
  font-weight: bold;
  font-size: 18px;

  @include media-breakpoint-down(md) {
    float: none;
    line-height: 19px;
    margin-bottom: 14px;
    margin-top: 14px;
    font-size: 16px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 6px;
    margin-top: 6px;
    font-size: 13px;
    line-height: 17px;
    font-weight: bold;
  }
}
.optionHeaderbutton {
  float: right;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-down(md) {
    float: none;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.desktopModal {
  z-index: 1000 !important;
  background-color: #fff;
  width: 400px;

  .contents {
    color: rgb(15, 15, 15);
    font-size: 1.4em;
    line-height: 1.3;

    h1 {
      font-size: 1.6em;
    }
    p {
      text-align: center;
      line-height: 1.2em;
      font-size: 16px;
      line-height: 21px;
      color: black;
      font-weight: bold;
    }
  }
  .actionButtons {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    flex: 1;
    height: 35px;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    border: none;
    font-size: 14px;
    text-transform: uppercase;

    button:first-child {
      margin-right: 10px;
      background-color: #707070;
      color: white;
      &:hover {
        background-color: lighten(#707070, +10%);
      }
    }
    button:last-child {
      margin-left: 10px;
    }
  }
}

.buttonActions {
  justify-content: center;

  button {
    flex: 1;
    width: 100%;
    height: 35px;
    border-radius: 5px;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    color: white;
    font-weight: 600;
    border: none;
    font-size: 14px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .buttonActive {
    background-color: #ff7900;
  }

  .buttonGrey {
    background-color: #707070;
  }
}

header.modalHeader {
  h2 {
    color: #fff;
    font-family: Montserrat, Helvetica, Arial, serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
  }
}

main.modalBody {
  padding-top: 23px;
  overflow-y: auto;

  p {
    color: #b9b9b9;
    font-family: Lato, Helvetica, Arial, serif;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    width: 95%;
    margin: 0 auto;
    padding: 1em;
  }

  a {
    text-decoration: none;
    font-weight: 400;
    font-size: inherit;
    transition: color 0.3s ease;
  }

  a.underline {
    text-decoration: underline;
    color: #ff7e00;
  }

  a[href^='mailto'] {
    color: #ff7e00;
  }
}

footer.modalActions {
  padding-top: 2em;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1em;
}

@media (max-width: 767px) {
  .lateralButton {
    position: absolute;
  }

  .left {
    left: 5px;
    z-index: 20;
  }

  .right {
    right: 5px;
    z-index: 20;
  }
}
