.title {
  margin: 0;
  font-weight: bold;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
  line-height: 26px;
}

.modal {
  width: 500px;
  max-height: 600px;
  transition: width 2s, height 2s;

  .description {
    text-align: center;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #9c9c9c;
  }
  p {
    margin-bottom: 20px;
  }

  .deviceList {
    overflow: auto;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 12px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  li {
    width: 100%;
  }
  .deviceButton {
    padding: 10px 20px;
    width: 100%;
    color: white;
    border: none;
    background: #2f2f2f 0% 0% no-repeat padding-box;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .deviceName {
      text-align: left;
      font: normal normal normal 16px/19px Lato;
      letter-spacing: 0px;
      color: #b9b9b9;
    }
    .status {
      text-align: left;
      font: normal normal normal 13px/16px Lato;
      letter-spacing: 0px;
      color: #747474;
    }
  }

  button:hover {
    background-color: #393a3a;
  }

  img {
    height: 24px;
  }

  footer {
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    button {
      width: unset;
    }
  }
}

.bpm {
  display: flex;
  color: var(--orange);
  font-weight: bold;
  align-items: center;
  .devName {
    padding-right: 0;
  }

  img {
    width: 20px;
    margin-left: 4px;
    margin-right: 4px;
    animation: heartbeat 3s infinite;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  5% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.75);
  }
  15% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
