@import '../../../../../css/variables';
@import '../../../../../css/breakpoints';

.trainingClass.bgWhite {
  background-color: white;
}

.trainingClass {
  width: 100%;
  height: 100%;
  background-color: #000;
  font-size: 13px;
  cursor: pointer;
  display: block;

  &:hover {
    color: white;
  }

  .white_overlay {
    display: block;
    background: linear-gradient(180deg, rgba(19, 19, 19, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    min-height: 70px;
    min-width: 100%;
  }

  /* Al hacer hover, la miniatura de la clase hace un pequeño zoom */
  /* No podemos hacerlo con background-size porque queremos que el background siempre ocupe toda la tarjeta. */
  .cover {
    overflow: hidden;
  }

  &:hover .cover .image {
    transform: scale(1.05);
  }

  /* Quitamos el zoom para que las las miniaturas se vean correctamente en tablet y mobile */
  @include media-breakpoint-down(lg) {
    &:hover .cover .image {
      transform: none;
    }
  }

  .cover {
    display: flex;
    min-height: 170px;

    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    position: relative;

    .image {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 0%;
      transition: transform 0.3s;

      @include media-breakpoint-down(sm) {
        min-height: 150px;
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      z-index: 1;

      .points {
        display: flex;
        gap: 3px;
        & > div {
          font-size: 14px;
        }
      }

      .materials {
        display: flex;
        align-items: center;
      }
    }

    .graphs {
      position: absolute;
      top: 0;
      right: 0;
      width: 32%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 0.75em;
    }
  }

  &.custom .cover {
    background-size: cover;

    &:hover {
      background-size: cover;
    }
  }

  .extra {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 12px;
    min-height: 50px;
    width: 100%;

    background-color: rgb(32, 32, 32);
    padding: 12px;

    .levelnr {
      display: flex;
      flex-direction: row;
      align-items: center;

      .on,
      .off {
        border-radius: 50%;
        width: 5px;
        height: 5px;
        margin: 2px;
      }

      .on:first-child,
      .off:first-child {
        margin-left: 5px;
      }

      .on {
        background-color: white;
      }

      .off {
        background-color: rgb(78, 78, 78);
      }
    }

    .year {
      text-transform: lowercase;
      text-align: center;
    }
    .duration {
      text-align: right;
    }
    .nonOfficial .year {
      text-align: left;
    }

    .trainingtype {
      width: 100%;
    }
  }
  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.listcontainer {
  padding: 20px 0 0 20px;
}

.title {
  position: relative;
  flex: 1;

  h3 {
    font-size: 15px;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    color: white;
    font-weight: 600;
    line-height: 17px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
  }
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
  margin: 10px auto;

  .optionLeft {
    flex-direction: column;
    margin-bottom: 10px;
  }

  @include media-breakpoint-down(sm) {
    // display: none;
    display: flex;
    flex-direction: column;

    .optionLeft {
      display: flex;
      flex-direction: row;
    }
  }
}

.editButton {
  text-align: center;
  border-radius: 4px;
  padding: 10px;
  background-color: #ff7900;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);

  &:hover {
    background-color: lighten(#ff7900, +10%);
  }
}

.container {
  position: relative;
  height: 100%;

  .editButtonContainer {
    position: absolute;
    right: 12px;
    bottom: 75px;
    text-align: right;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .favoriteStyle {
    bottom: 95px;
  }

  &:hover .editButtonContainer {
    opacity: 1;
  }

  a {
    display: flex;
    flex-direction: column;
  }
}
