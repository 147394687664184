@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.point {
  width: 2em;
  height: 2em;
  display: flex;
  background: #333;
  border-radius: 48px;
  align-items: center;
  justify-content: center;
  font-size: 1.375em;
  margin-right: 5px;

  @include media-breakpoint-down(xl) {
    font-size: 1.25em;
  }
  @include media-breakpoint-down(lg) {
    font-size: 1.125em;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1em;
  }

  .value {
    color: white;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    font-style: normal;
    font-weight: 700;

    letter-spacing: 1.18px;
    line-height: 1;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.345);
    white-space: nowrap;

    margin-top: 1px;
    margin-left: 1px;
  }
}

.point.small {
  width: 1.85em;
  height: 1.85em;

  font-size: 1.075em;

  @include media-breakpoint-down(xl) {
    font-size: 1.03em;
  }
  @include media-breakpoint-down(lg) {
    font-size: .95em;
  }
  @include media-breakpoint-down(sm) {
    font-size: .6em;
  }
}

.point.normal {
  width: 2em;
  height: 2em;

  font-size: 1.375em;

  @include media-breakpoint-down(xl) {
    font-size: 1.25em;
  }
  @include media-breakpoint-down(lg) {
    font-size: 1.125em;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1em;
  }
}

.point.resistance {
  background-color: #fedb31;
}
.point.force {
  background-color: #ec1530;
}
.point.flexibility {
  background-color: #6cb21f;
}
.point.mind {
  background-color: #1d8cb9;
}
