@import '../../../../../css/variables';
@import '../../../../../css/breakpoints';

.container {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  padding: 30px;

  background-image: url('@/img/surveys/bg-nps-web.png');
  background-size: 100% 100%;

  text-align: center;

  @include media-breakpoint-down(xxl) {
    padding: 15px 20px;
  }

  .closeIcon {
    position: absolute;
    top: 0;
    right: 0;

    padding: 15px;

    background: none;
    outline: none;
    border: none;
  }

  .surveyContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 7px;

    @include media-breakpoint-down(xxl) {
      gap: 4px;
    }
  }
  .surveyContent.confirmed {
    gap: 5px;
    margin-top: 20px; 
    max-width: 200px;
  }
  .surveyContent.declined {
    gap: 25px;

    @include media-breakpoint-down(xxl) {
      gap: 5px;
    }
  }

  .title {
    color: white;
    font: normal normal bold 15px Montserrat;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    color: white;
    font: normal normal normal 14px Lato;

    @include media-breakpoint-down(xxl) {
      gap: 5px;
    }
  }

  .actions {
    display: flex;
    gap: 15px;
  }
}