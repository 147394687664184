@use 'sass:math';

.progressBar {
  width: 100%;
  background-color: #3f3f3f;
  height: 25px;
  position: relative;
  text-align: center;
  border-radius: 40px;
  overflow: hidden;

  .progress {
    height: 100%;
    width: 0;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }

  .percent {
    width: 100%;
    height: 100%;
  }

      span {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
    }

  &.striped .percent {
    position: absolute;
    background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(0, 0, 0, 0.15) 50%,
      rgba(0, 0, 0, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
    animation: progress-bar-stripes 0.5s linear infinite;


  }

  @keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }

  .progress {
    position: absolute;
    transition: 1s;
  }
  &.progress100 {
    border-radius: 40px;
    overflow: hidden;
  }

  @for $i from 0 through 100 {
    $value: ($i * 1);
    &.progress#{$value} .progress {
      width: #{percentage(math.div($value, 100))};
    }
  }
}
