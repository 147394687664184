.formSelect {
  border: none;
  background: #1D1D1D url(/src/img/dropdown-arrow@2x.png) no-repeat;
  background-size: 17px;
  background-position: calc(100% - 10px);
  border-radius: 3px;
  box-shadow: none;
  color: #B9B9B9;
  font: normal normal normal 14px/18px Lato;
  padding: 10px;
  margin-right: 20px;
  text-shadow: none;
  background-color: hsla(0,0%,100%,.1);
}
