@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.postCard {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: relative;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
}

.extra {
  width: 100%;
  background-color: rgb(32, 32, 32);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;

  @include media-breakpoint-up(xxl) {
    padding: 16px;
  }
}

.title {
  flex: 1;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 700;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  height: 4.2em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.date {
  font-size: 13px;
  font-family: 'Lato', Helvetica, Arial, serif;
  line-height: 17px;
  color: #b9b9b9;
  text-transform: lowercase;
}

.cover {
  display: flex;
  min-height: 100px;
  background-size: 110%;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: background-size 0.3s;

  @include media-breakpoint-up(xxl) {
    min-height: 140px;
  }

  @include media-breakpoint-down(lg) {
    background-size: cover;
  }

  &:hover {
    background-size: 120%;

    @include media-breakpoint-down(lg) {
      background-size: cover;
    }
  }

  @include media-breakpoint-down(sm) {
    min-height: 100px;
  }
}

.trianglecategory {
  min-width: 20px;
  min-height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
