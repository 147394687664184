.container {
  background-color: var(--licorice);
  color: white;
  border-radius: 12px;
  display: flex;
  max-width: 350px;
  padding: 7px 12px;
  box-shadow: 0px 4px 8px #00000064;
  margin: auto;

  .icon {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    flex: 4;
  }

  img {
    width: 50px;
  }

  button {
    all: unset;
    color: var(--orange);
  }
  button:hover {
    color: rgb(252, 177, 80);
  }

  h2 {
    font-weight: 500;
    font-size: large;
    padding: none !important;
  }
  h4 {
    font-size: medium;
  }

  > * {
    padding: 5px 10px;
  }
}

.container_big {
  background-color: var(--licorice);
  color: white;
  border-radius: 12px;
  padding: 37px;
  max-width: 525px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    flex: 4;
  }

  img {
    width: 90px;
  }

  button {
    all: unset;
    color: var(--orange);
    margin-top: 20px;
    border-radius: 5px;
    padding: 7px 11px;
    transition: all 500ms;
  }
  button:hover {
    color: rgb(252, 177, 80);
    background-color: var(--masala);
  }

  .sm {
    width: 300px;
  }
  p {
    font: normal normal normal 15px/23px Lato;
    color: #747474;
    font-size: medium;
    font-weight: 400;
  }

  > * {
    padding: 5px 10px;
  }
  h2 {
    font: normal normal normal 18px/25px Lato;
    font-weight: 400;
    font-size: large;
    padding: 22px 0 5px 0;
    color: #9c9c9c;
  }
}

.shadow {
  box-shadow: 0px 4px 8px #00000064;
}
