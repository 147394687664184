.iconNotification {
  position: relative;
}

.iconNotificationIndicator {
  background-color: var(--salmon-pearl);
  border-radius: 7px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: 12px;
  width: 7px;
}
