@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.groupedTrainings {
  padding: 40px;
  border-bottom: 1px solid #343434;

  @include media-breakpoint-down(sm) {
    padding: 25px;
  }

  &:hover {
    background-color: #0e0e0e;
  }
  .title {
    margin: 1em 0;
    color: white;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  .group {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
}
