.social {
  display: flex;
  align-content: center;
}

.social button.reply {
  border: none;
  background: transparent;
  color: #aaa;
  text-transform: uppercase;
  font-size: 13px;
  font-family: 'Lato', Helvetica, Arial, serif;
}

.social button.reply:disabled {
  opacity: 0.4;
  pointer-events: none;
}
.comments {
  margin: 0.75em 0;
  line-height: 20px;
  font-size: 15px;
  color: #b9b9b9;
  cursor: text;
  user-select: text;
}

.user_name {
  color: #858585;
  font-weight: 700;
  font-size: 15px;
}

.user_name.primary {
  color: var(--orange);
}

.date {
  color: #858585;
  font-weight: 400;
  font-size: 14px;
}
