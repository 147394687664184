.container {
  position: relative;

  width: 100%;
  max-height: 600px;

  margin: 1em 0;

  background-color: black;

  .video {
    width: 100%;
    height: 100%;
    max-height: inherit;
  }

  .controls {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
}