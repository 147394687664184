$border: 1px solid #343434;

.container { border-bottom: $border; }

.container.isFirst { border-top: $border; }

.container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .default {
    text-align: right;

    font: normal normal normal 15px/21px Lato;
    color: #ff7900;
  }
}

.container .header .containerButton {
  display: flex;
  gap: 10px;

  padding: 14px 0;

  background-color: transparent;
  outline: none;
  border: none;

  .label {
    white-space: nowrap;
    text-align: left;

    font: normal normal normal 15px/18px Lato;
    color: white;
  }
}

.container .content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease-in-out;

  p {
    text-align: left;
    width: 100%;
    padding: 0;
    padding-bottom: 25px;
  }
}

.container.showContent {
  .chevron {
    transform: rotate(180deg);
  }

  .content {
    max-height: 500px;
  }
}