@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.editableMenuItem button {
  display: flex;
  align-items: center;
  gap: 30px;

  background-color: #262626;
  color: #aaaaaa;

  font-family: "Lato";
  font-size: 14px;
  line-height: 19px;

  width: 100%;

  padding: 0.5rem 1.5rem;

  border: none;
  outline: none;

  .icon {
    position: relative;
    
    img, .img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .text {
    white-space: nowrap;
  }

  &:hover {
    background-color: #2f2f2f !important;
    color: white;

    cursor: pointer;
  }
}