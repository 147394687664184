@import '../../../../css/variables';
@import '../../../../css/breakpoints';
 
 .playlist_thumbnail{
    width: 257px;
    height: 130px;
    cursor: pointer;
    position:relative;
    
    
    @include media-breakpoint-up(xxl){
      width:328px;
      height:150px;
    }
 }

.playlist_category {
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
  
  span {
    font-family: 'Lato', Helvetica, Arial, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 25px;
  }

  img{
    max-width: 180px;
    @include media-breakpoint-up(xxl){
      max-width: 210px;
    }
    
  }
  
  div{
    display: flex;
    flex-direction: column;
    margin-bottom:5px;
  }
}
