@import '../../../../../../src/css/variables';
@import '../../../../../../src/css/breakpoints';

.titleContainer {
  margin-bottom: 1em;
}

.title {
  font-size: 32px;
  color: #FFF;
  font-family: "Montserrat";
  font-weight: bold;
}

.commentsContainer {
  display: flex;
  margin-top: 50px;

  @include media-breakpoint-down(xl) {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
  }

  .comments,
  .shares {
    flex: 2;
    margin-right: 30px;

    @include media-breakpoint-down(xl) {
      margin-right: 0;
      margin-top: 1em;
    }
  }

  .trainings {
    flex: 1;
    font-size: 15px;
    font-family: 'Lato', Helvetica, Arial, serif;

    @include media-breakpoint-down(xl) {
      flex-basis: 100%;
      margin-top: 50px;
    }
  }
}

.comments .form {
  align-items: flex-start;
  background-color: var(--woodsmoke);
  border-radius: 5px;
  display: flex;
  min-height: 148px;
  overflow: hidden;
  margin: 20px 0 40px 0;
}

.lineDivider {
  background-color: var(--shark);
  height: 1px;
  width: 100%;
  margin: 0.75em 0 1em 0;
}

.commentsContainer h2 {
  color: var(--pink-swan);
  font-family: "Lato";
  font-size: 17px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 1.36px;
  text-align: left;
}

.commentsContainer h3 {
  color: white;
  font-family: "Lato";
  font-size: 19px;
}