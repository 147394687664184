.container {
  &:has(*.hide) {
    width: 0px;
    margin-right: 0px;

    transition: 
      width .5s cubic-bezier(0.4, 0, 0.2, 1), 
      margin-right .5s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .hide {
    position: absolute;
    width: 100%;
    height: 100%;

    opacity: 0;
    transition: opacity .3s ease;
  }
}