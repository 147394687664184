@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.modal {
  width: 75%;
  max-width: 450px;
  color: #c7c7c7;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  @include media-breakpoint-down(md) {
    width: 90%;
    top: 3vh;
  }
}

.modal .title {
  align-self: flex-end;
  line-height: 20px;
  min-height: 26px;
  text-align: center;
  width: 100%;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  color: #fff;
  font-family: 'Montserrat';
  margin-bottom: 1em;
}

.switch_container {
  display: flex;
  margin: 2em 0;
  line-height: 1.2;
  align-items: center;
}

.switch_label {
  flex: 1;
  color: rgba(185, 185, 185, 1);
  font-family: 'Lato', Helvetica, Arial, serif;
  text-transform: none;
  font-weight: normal;
  margin-right: 20px;

  cursor: pointer;
  .span0 {
    font-size: 17px;
    color: #fff;
    line-height: 26px;
  }
  .span1 {
    font-size: 15px;
  }
}
.switch_switch {
}

.medias_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 15px;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
  }

  .media {
    flex: calc(1 / 3);

    &:nth-child(1) {
      margin-right: 10px;
    }
    &:nth-child(2) {
      margin-left: 5px;
      margin-right: 5px;
    }
    &:nth-child(3) {
      margin-left: 10px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      display: flex;
      &:nth-child(n) {
        margin: 0;
      }
    }
  }
}

.preferences {
  h2 {
    text-transform: uppercase;
    margin: 2em 0 1em;
    font-weight: bold;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    margin-top: 40px;
  }
  li p {
    font-size: 14px;
    line-height: 20px;
  }

  a {
    color: #ff7900;
  }
}
.input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 18px;
  p {
    padding-right: 15px;
  }

  label select {
    margin-right: 20px;
  }
  input {
    font-size: 14px;
    width: 135px;
    height: 42px;
  }
}

.input_container_colors {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .color_input {
    margin: 5px;
  }
}

.separator {
  height: 1;
  border: none;
  border-bottom: 1px solid #333;
  margin: 1em 0;
}
.info {
  color: #666;
  font-size: 13px;
  line-height: 16px;
}

.configuration {
  label {
    width: 135px;
  }
}

.slider {
  color: #c7c7c7;
  margin-top: 2em;

  h1 {
    color: white;
    font-weight: 700;
  }
  .sliderDescription {
    float: right;
    font-size: 14px;
  }
}
.buttons {
  margin-top: 3em;
  text-align: center;
  display: flex;
  justify-content: center;
}
