@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 1000;
  align-items: center;
  justify-content: center;
}

.shadow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  animation: fadein 0.25s ease-out;
  @include media-breakpoint-down(md) {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.modal {
  max-width:360px;
  position: relative;
  background: white;
  color: black;
  border-radius: 6px;
  box-shadow: 0px 17px 56px black;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 30px 45px;
  margin: 16px;
  animation: fadein 0.25s ease-in-out;

  @include media-breakpoint-down(md) {
    padding: 35px 30px;
    max-width: 88%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal .close {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  transform: rotate(180deg);
  width: 20px;
  border: none;
  padding: 0;
  margin: 0;
  background: url('../../../../img/icon-close.svg');
  background-size: cover;
}

.modal .title {
  align-self: flex-end;
  line-height: 22px;
  min-height: 26px;
  text-align: center;
  width: 100%;
  color: black;
  font-family: 'Lato', Helvetica, Arial, serif;
  font-size: 16px;
  font-style: normal;
  font-weight:600;
}


.modal .buttons{
  margin-top: 1em;
  display: flex;
  justify-content: center;


}
