/* Button.module.css */
.buttonContainer{
   display: flex;
  align-items: center;
  justify-content: center;
}

.socialButton {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 0.25rem;
  border-width: 1px;
  transition: background-color 0.3s ease;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-top: 20px;
  max-width: 450px;
  width: 100%;
}
.buttonContainer span{
  width: 100%;
}
.btnGoogle {
  background-color: #F8F8F8;
  color: black;
  border-color: #D6D6D6;
}

.btnGoogle:hover {
  background-color: #D8D8D8;
}

.socialButton img {
  position: absolute;
  left: 15px;
  width: 24px;
  height: 24px;
  vertical-align: middle; /* Alinea verticalme
  transition: color 0.3s ease; /* Transición para el color del ícono en hover */

}
/* Estilo para el botón de inicio de sesión con Facebook */
.btnFacebook {
  background-color: #3863B0;
  color: white;
  border-color: #3863B0;
}
.btnApple {
  background-color: #000000; /* Fondo negro */
  color: #ffffff; /* Texto blanco */
  border: none; /* Sin borde */
}

.btnApple:hover {
  background-color: #333333; /* Fondo gris oscuro en hover */
}
.btnFacebook:hover {
  background-color: #4873C0;
}
.socialButton:hover .icon {
  color: #f0f0f0; /* Color del ícono en hover (puedes ajustar este color) */
}