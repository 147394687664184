.noResults {
  justify-content: center;
  margin: 2.5em 0 10vh 0;
  color: #9c9c9c;
  font-size: 16px;
  line-height: 1.7;
  text-align: center;
  p {
    margin: 1em 0;
  }
}
