
.row{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;

  margin-bottom:5px;

  &:last-child{
    margin-bottom:0;
  }
}

.dropdownMenu{
  position: absolute;

  background-color: #262626;
  background-clip: padding-box;

  width: 166px;
  padding: 10px 5px;

  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;

  box-shadow: 0 3px 6px rgb(0 0 0 / 50%);
  z-index: 1;
}