@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.secondary-menu .profile {
  align-items: center;
  align-self: flex-start;
  background-color: black;
  border-radius: 21px;
  display: flex;
  height: 40px;
  margin-left: 31px;
  min-width: 148px;
  padding: 0 4px;
  border: 0;
  @include media-breakpoint-down(xl) {
    align-self: center;
    min-width: auto;
    margin-left: 20px;
  }

  .user-image {
    border-radius: 16px;
    height: 32px;
    width: 32px;
    @include media-breakpoint-down(xl) {
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }
  }
  .user-name {
    letter-spacing: 0px;
    line-height: 14px;
    margin-left: 9px;
    margin-top: 5.18px;
    min-height: 19px;
    min-width: 73px;
    white-space: nowrap;
    @include media-breakpoint-down(xl) {
      display: none;
    }
  }
  .chevron-down {
    height: 8px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 4px;
    width: 12px;
    @include media-breakpoint-down(xl) {
      display: none;
    }
  }
}

/* dropdown */
.secondary-menu .dropdown-menu {
  min-width: 11rem;

  .dropdown-item .icon {
    display: inline-block;
    margin-right: 1em;
    min-width: 1em;
  }
}
