@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  @supports (overflow-x: clip) {
    overflow-x: clip;
  }

  .inner {
    padding: $page-vertical-padding $page-horizontal-padding;
    box-sizing: border-box;
    width: 100%;
    flex: 1;
    position: relative;

    @include media-breakpoint-down(sm) {
      padding: $page-vertical-padding-sm $page-horizontal-padding-sm;
    }
  }
  &.nopadding .inner {
    padding: 0;
  }
}
.containerModal {
  background-color: #2d2d2d;
}

.containerModalright {
  background-color: #121212;
}

.container h2:first-child {
  margin: 0 0 1em 0;
  @include media-breakpoint-down(sm) {
    margin: 0 0 0.6em 0;
  }
}
