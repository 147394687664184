.inputContainer {
  position: relative;
  width: 100%;
}

.formInput {
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: #b9b9b9;
  font: normal normal normal 14px/18px Lato;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  &:disabled {
    opacity: 0.5;
    color: #999;
  }

  &::placeholder {
    color: #747474;
  }
}

.iconButton {
  position: absolute;
  top: 50%;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  transform: translateY(-50%);

  background-color: transparent;
  border: none;
  padding: 15px;

  cursor: pointer;
}

.paddingRight {
  padding-right: 50px;
}
