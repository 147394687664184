@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.slider {
  position: relative;
  touch-action: pan-y;

  margin-left: -$page-horizontal-padding;
  margin-right: -$page-horizontal-padding;
  padding-left: $page-horizontal-padding;
  padding-right: $page-horizontal-padding;

  @include media-breakpoint-down(sm) {
    margin-left: -$page-horizontal-padding-sm;
    margin-right: -$page-horizontal-padding-sm;
    padding-left: $page-horizontal-padding-sm;
    padding-right: $page-horizontal-padding-sm;
  }
}

.noMargin {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.userTags {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  overflow: auto;
  .slide {
    width: auto !important;
    padding-right: 0 !important;
  }
}
.sliderMask {
  overflow-x: hidden;
  &.showPeek {
    display: flex;
    overflow-x: visible;
  }
}

.track {
  position: relative;
  white-space: nowrap;
  .animating & {
    transition: 0.5s ease-in-out;
    pointer-events: none;
  }
}

.slide {
  box-sizing: border-box;
  position: relative;
  white-space: normal;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  vertical-align: top;
  flex-grow: 1;
  margin-right: 18px;
}

.slide.automaticSize {
  width: 257px;

  @include media-breakpoint-up(xxl) {
    width: 328px;
  }
}

/* buttons */
.arrowleft,
.arrowright {
  opacity: 0.9;
  background-color: rgba(20, 20, 20, 0.5);
  position: absolute;
  z-index: 4;
  width: 55px;
  height: 100%;
  text-align: center;
  border: 0;
  cursor: pointer;
  align-self: center;
  color: #fff;
  transition: 0.25s ease-in-out;
  top: 0;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.arrowleft {
  left: 0px;
  background: linear-gradient(-90deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.7) 100%);
}
.arrowright {
  right: 0px;
  background: linear-gradient(90deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.7) 100%);
}

.arrowleft,
.arrowright {
  opacity: 0;
  img {
    height: 35px;
  }
}

.arrowleft:hover,
.arrowright:hover {
  transition: all 0.25s ease-in;
  background-color: rgba(20, 20, 20, 0.5);
  img {
    transition: all 0.25s ease-in;
    height: 45px;
  }
}

.slider:hover .arrowleft,
.slider:hover .arrowright {
  opacity: 1;
  transition: 0.25s ease-in-out;
}
.slider .arrowleft:focus-visible,
.slider .arrowright:focus-visible {
  opacity: 1;
  transition: none;
}
