@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.alert {
  position: fixed !important;
  width: 400px;
  z-index: 1000;
  margin:0 auto;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: -200px;
  margin-left: -200px;
  @include media-breakpoint-down(sm){
    width: 320px;
    margin-left: -160px;
  }
}
.white {
  position: fixed !important;
  width: 430px;
  z-index: 1000;
  background-color: white;
  padding: 1% 3%;
  font-size: 18px;
  margin:0 auto;
  @include media-breakpoint-down(md) {
    padding: 1% 10%;
    //left: 58%;
     }

  .description {
    color: black;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .buttonActions {
    display: inline-flex;
    margin:15px auto;

    button:first-child {
      margin-right: 15px;
    }

    button:last-child{
      margin-top:0px;
    }
  }
}
.title {
  align-self: flex-end;
  line-height: 24px;
  min-height: 36px;
  text-align: center;
  width: 100%;
  color: var(--pink-swan);
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 20px;
  font-weight: 700;
  color: white;
  @include media-breakpoint-down(sm){
    margin-top: 15px;
    font-size:18px;
  }
}

.description {
  text-align: center;
  line-height: 1.2em;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size:16px;
  line-height: 21px;
}

div.image{
  margin:auto;
  img{
    margin-left: 30px;
  }
}

.buttonActions{
    margin: 0 auto;
    display: contents;

    button:last-child{
      margin-top:10px;
    }
  }