@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.profile {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto 2rem auto;
  width: 90%;
  max-width: 500px;
}

%button {
  align-items: center;
  background-color: var(--heat-wave);
  border-radius: 3px;
  height: 50px;
  overflow: hidden;
  min-width: 100%;
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  border: none;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Botones paso 3 */

%button_objectives {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 5%;
  text-align: left;
  border-radius: 50px;
  height: auto;
  line-height: 18px;
}

/* Estilos generales Profile Config */

.title {
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 24px;
  font-weight: 700;
  color: white;
  text-align: center;
  line-height: 28px;
}

.description {
  color: white;
  text-align: center;
  line-height: 21px;
  font-size: 15px;
  margin-top: 20px;
}

.steps {
  font-family: 'Lato', Helvetica, Arial, serif;
  font-size: 12px;
  font-weight: 300;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 7% 0;
}

.formContainer {
  width: 100%;
  margin: 30px 0;

  p {
    line-height: 19px;
    font-size: 15px;
    white-space: normal;
    width: 80%;
  }
}

.button {
  @extend %button;
}
.button:disabled {
  background: #343434;
  color: #b9b9b9;
}
.container {
  fieldset {
    margin-top: 30px;
  }

  label {
    color: #fff;
    display: block;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 14px;
  }

  select:last-child {
    margin-right: 0px;
  }
}

.form,
.form_input,
.form_input_objectives {
  width: 100%;
}

.birthday {
  display: flex;
  align-content: space-between;
}

.txt {
  font-family: 'Lato', Helvetica, Arial, serif;
  font-size: 14px;
  text-transform: initial;
  font-weight: 400;
}

.button_objectives {
  @extend %button;
  @extend %button_objectives;
}

.form_input label {
  @extend %button;
  display: block;
  margin-bottom: 20px;
  padding-top: 17px;
  background-color: #2f2f2f;
  color: #b9b9b9;
  cursor: pointer;
  white-space: normal;
}

.form_input_objectives label {
  @extend %button;
  @extend %button_objectives;
  display: block;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #2f2f2f;
  color: #b9b9b9;
  cursor: pointer;
}

// input[type='checkbox'],
input[type='radio'] {
  display: none;
  margin-bottom: 0rem;
  margin-left: 0;
}

input[type='checkbox']:checked + label,
input[type='radio']:checked + label {
  @extend %button;
  display: block;
  margin-bottom: 20px;
  padding-top: 17px;
  cursor: pointer;
}

.checkbox label {
  background-image: url(/src/img/checkbox-empty@2x.png);
  background-repeat: no-repeat;
  background-position: 95%;
  background-size: 28px;
}

.checkbox input[type='checkbox']:checked + .force,
.checkbox input[type='checkbox']:checked + .resistance,
.checkbox input[type='checkbox']:checked + .mind,
.checkbox input[type='checkbox']:checked + .flexibility,
.checkbox input[type='checkbox']:checked + .all {
  @extend %button_objectives;
  background-image: url(/src/img/checkbox-checked@2x.png);
  background-repeat: no-repeat;
  background-position: 95%;
  padding:20px 20px 20px 30px;
  display: block;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 50px;
}

input[type='checkbox']:checked + .force {
  background-color: #f13b46;
  border-radius: 50px;
  height: auto;
}

input[type='checkbox']:checked + .resistance {
  background-color: #d59b00;
  border-radius: 50px;
  height: auto;
}

input[type='checkbox']:checked + .flexibility {
  background-color: #6cb21f;
  border-radius: 50px;
  height: auto;
}

input[type='checkbox']:checked + .mind {
  background-color: #1d8cb9;
  height: auto;
  border-radius: 50px;
}

input[type='checkbox']:checked + .all {
  background-color: var(--heat-wave);
}

.button_ignore {
  padding: 10px;
  color: #b9b9b9;

  &:hover {
    color: #b9b9b9;
  }
}

/* Activities */
.activitiesGroups {
  width: 100%;
}
.activitiesGroupContainer {
  border-bottom: 1px solid #343434;
  margin-bottom: 20px;
}
.activitiesGroupName {
  text-transform: uppercase;
  margin: 20px 0;
}
.activitiesGroup {
  display: flex;

  @include media-breakpoint-down(sm) {
    display: block;
  }
}
.activitiesActivity {
  flex: 1;
  margin: 0 5px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.activitiesActivity label {
  @extend %button;
  @extend %button_objectives;
  display: block;

  padding: 15px !important;
  text-align: center !important;
  background-color: #2f2f2f;
  color: #b9b9b9;
  cursor: pointer;
}

// input[type='checkbox'],
input[type='radio'] {
  display: none;
  margin-bottom: 0rem;
  margin-left: 0;
}

input[type='checkbox']:checked + label,
input[type='radio']:checked + label {
  @extend %button;
  display: block;
  margin-bottom: 20px;
  padding-top: 17px;
  cursor: pointer;
}
