.page {
  width: 100vw !important;
}
.messages {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  opacity: 1;
  line-height: 1.3;
  // transition: opacity 1s ease-in-out;
}

.progress{
  position: absolute;
  width: 100%;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}