@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.modal {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .header {
    padding: 0em 2em;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    color: white;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    margin-bottom: 10px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0.5em;
      margin-top: 20px;
    }
  }

  .content {
    width: 100%;

    text-align: center;
  }

  .footer {
    display: flex;
    gap: 20px;

    margin-top: 20px;
  }
}