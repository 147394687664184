@import '../../../css/variables';
@import '../../../css/breakpoints';

.container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  &.containerScroll {
    justify-content: start;
  }
  flex-direction: column;
  margin: 0 auto 2rem auto;
  width: 90%;
  text-align: left;

  .title {
    font-family: 'Montserrat', Helvetica, Arial, serif;
    font-size: 24px;
    font-weight: 700;
    color: white;
    margin-bottom: 20px;
    text-align: center;
    line-height: 28px;
  }

  .body {
    text-align: center;
    color: white;
    line-height: 32px;
    font-size: 24px;
    margin-bottom: 2em;

    .bodyText {
      margin: 0 5% 5% 5%;
    }
    .link {
      font-size:24px;
      font-weight: 900;

    }
  }

  .button {
    min-width: 340px;
    margin-top: 20px;
  }
}