.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .headerContainer {
    display: flex;
    gap: 12px;
    align-items: center;

    .header {
      font: normal normal bold 18px/23px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      text-transform: uppercase;
    }
  }

  .headerContainer.toggeable {
    cursor: pointer;
  }

  .chevronUp {
    transform: rotate(180deg);
  }
}
