@import '../../../../../src/css/variables';
@import '../../../../../src/css/breakpoints';

.profileDisplay {
  text-align: center;
  padding:20px;
  main {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 1.25;
    color: white;
    font-weight: 700;
    margin-bottom:10px;
    @include media-breakpoint-down(md){
      font-size:16px;
      margin-bottom:5px;
    }
    
  }
  footer {
    
    text-transform: uppercase;
    font-size: 14px;

  }
}
