@import '../../../../css/variables';
@import '../../../../css/breakpoints';

.containerInput {
  background-color: rgba(255, 255, 255, 0.1);
  margin: 5px;
  border-radius: 4px;
  max-height: 40vh;
  overflow-y: scroll;
}
  
.emptyContainer{
  color: #747474;
  text-align: center;
  font-size: 14px;
  margin: 20px auto;
}

.containerTagsAdded {
  display: flex;
  flex-wrap: wrap;

  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 4px 5px;
  align-items: center;

  input {
    background-color: transparent!important;
  }
}

.disableScroll {
  overflow: hidden;
}

.createTagButton {
  display: flex;
  align-items: center;
  margin-right: 10px;
}