@import '../../../../css/variables';
@import '../../../../css/breakpoints';


.subscribeButton{
  width: 100%;
  @include media-breakpoint-up(lg) {
    display: none;
  }

  .button {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 13px 20px;
    width: 100%;
  }
}