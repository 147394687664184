.button {
  border: none;
  background: transparent;
  display: flex;
  margin: 0;
  padding: 0;
  color: #aaaaaa;
  align-items:center;

  .icon {
    margin-right: 5px;
    height: 24px;
  }
  .count {
    margin-right: 20px;
  }
}
